angular
    .module('carreterasAdmin')
    .factory('carreterasAdminService', ['$http', '$q', '__env', function carreterasAdminService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var carreterasUrl = 'carreteras';

        function getCarreteras() {
            return $http.get(serviceUrl + carreterasUrl);
        }
        function putCarreteras(carreteras) {
            return $http.put(serviceUrl + carreterasUrl + '/guardar', carreteras);
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    errores.push(response.ModelState[key][i]);
                }
            }
            return errores[0];
        }

        return {
            getCarreteras: getCarreteras,
            putCarreteras: putCarreteras,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState
        }
    }]);
