angular.module('cultivosAprovechamientosAdmin', [])
       .controller('cultivosAprovechamientosAdminController', ['$scope', '$location', 'cultivosAprovechamientosAdminService', 'Excel', function ($scope, $location, cultivosAprovechamientosAdminService, Excel) {
           cultivosAprovechamientosAdminService.getCultivosAprovechamientos()
                             .success(function (data) {
                                 $scope.cultivosaprovechamientos = data;
                             });

           $scope.idNuevo = 0;

           $scope.nuevoCultivoAprovechamiento = function () {
               $scope.idNuevo--;
               var cultivoaprovechamiento = {
                   Id: $scope.idNuevo,
                   Descripcion: "",
                   Codigo: ""
               };
               $scope.cultivosaprovechamientos.push(cultivoaprovechamiento);
           }
           $scope.eliminarCultivoAprovechamiento = function (cultivoaprovechamiento) {
               var index = $scope.cultivosaprovechamientos.indexOf(cultivoaprovechamiento);
               $scope.cultivosaprovechamientos.splice(index, 1);
           }

           $scope.guardarCultivosAprovechamientos = function () {
               cultivosAprovechamientosAdminService.putCultivosAprovechamientos($scope.cultivosaprovechamientos)
                            .success(function (data) {
                                alert("Datos guardados correctamente");
                            })
                           .error(function (response) {
                               $scope.error = cultivosAprovechamientosAdminService.obtenerPrimerErrorModelState(response);
                               alert($scope.error);
                           });
           }

           $scope.exportarExcel = function () {
               Excel.exportar('dvCultivosAprovechamientos', 'tbCultivosAprovechamientos', 'CultivosAprovechamientos');
           };
       }]);
