angular
    .module('elementosCuadroPreciosAdmin')
    .factory('elementosCuadroPreciosAdminService', ['$http', '$q', '__env', function elementosCuadroPreciosAdminService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var elementoscuadropreciosUrl = 'elementoscuadroprecios';

        function getElementosCuadroPrecios() {
            return $http.get(serviceUrl + elementoscuadropreciosUrl);
        }
        function getConceptos() {
            return $http.get(serviceUrl + elementoscuadropreciosUrl + '/conceptos');
        }
        function getClasificaciones() {
            return $http.get(serviceUrl + elementoscuadropreciosUrl + '/clasificaciones');
        }
        function getUnidadesElementos() {
            return $http.get(serviceUrl + elementoscuadropreciosUrl + '/unidadeselementos');
        }
        function putElementosCuadroPrecios(elementosCuadroPrecios) {
            return $http.put(serviceUrl + elementoscuadropreciosUrl + '/guardar', elementosCuadroPrecios);
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    errores.push(response.ModelState[key][i]);
                }
            }
            return errores[0];
        }

        return {
            getElementosCuadroPrecios: getElementosCuadroPrecios,
            getConceptos: getConceptos,
            getClasificaciones: getClasificaciones,
            getUnidadesElementos: getUnidadesElementos,
            putElementosCuadroPrecios: putElementosCuadroPrecios,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState
        }
    }]);
