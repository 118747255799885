angular.module('tiposInteresAdmin', [])
       .controller('tiposInteresAdminController', ['$scope', '$location', 'tiposInteresAdminService', function ($scope, $location, tiposInteresAdminService) {
           tiposInteresAdminService.getTiposInteres()
                             .success(function (data) {
                                 $scope.tiposinteres = data;
                             });

           $scope.idNuevo = 0;

           $scope.nuevoTipoInteres = function () {
               $scope.idNuevo--;
               var tipointeres = {
                   Id: $scope.idNuevo,
                   FechaInicio: null,
                   FechaFin: null,
                   Interes: 0
               };
               $scope.tiposinteres.push(tipointeres);
           }
           $scope.eliminarTipoInteres = function (tipointeres) {
               var index = $scope.tiposinteres.indexOf(tipointeres);
               $scope.tiposinteres.splice(index, 1);
           }
           $scope.guardarTiposInteres = function () {
                tiposInteresAdminService.putTiposInteres($scope.tiposinteres)
                            .success(function (data) {
                                alert("Datos guardados correctamente");
                            })
                            .error(function (response) {
                                $scope.error = tiposInteresAdminService.obtenerPrimerErrorModelState(response);
                                alert($scope.error);
                            });
           }
       }]);
