angular.module('usuariosAdmin')
       .controller('usuarioAdminController', ['$scope', '$routeParams', '$location', 'usuariosAdminService', function ($scope, $routeParams, $location, usuariosAdminService) {
           usuariosAdminService.getUsuario($routeParams.id)
                             .success(function (data) {
                                 $scope.usuario = data;
                             });
           usuariosAdminService.getRoles()
                            .success(function (data) {
                                $scope.roles = data;
                            });

           $scope.volver = function () {
               $location.path("/admin/usuarios");
           };

           $scope.editarUsuario = function () {
               usuariosAdminService.put($scope.usuario).success(function (result) {
                   alert(result);
                   $location.path("/admin/usuarios");
               }).error(function (response) {
                   $scope.error = usuariosAdminService.obtenerPrimerErrorModelState(response);
                   alert($scope.error);
               });
           }
       }]);