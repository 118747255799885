angular.module('usuariosAdmin')
       .controller('nuevoUsuarioAdminController', ['$scope', '$routeParams', '$location', 'usuariosAdminService', function ($scope, $routeParams, $location, usuariosAdminService) {
           $scope.error = '';
           $scope.rolUsuario = 2;

           usuariosAdminService.getRoles()
                          .success(function (data) {
                              $scope.roles = data;
                          });

           $scope.volver = function () {
               $location.path("/admin/usuarios");
           };

           $scope.guardarUsuario = function () {
               usuariosAdminService.post($scope.usuario, $scope.rolUsuario).success(function (result) {
                   alert(result);
                   $location.path("/admin/usuarios");
               }).error(function (response) {
                   $scope.error = usuariosAdminService.obtenerPrimerErrorModelState(response);
                   alert($scope.error);
               });
           }
       }]);

