angular
    .module('expedientes')
    .factory('expedientesService', ['$http', '$q', '$filter', '__env', function clavesService($http, $q, $filter, __env) {
        var serviceUrl = __env.apiUrl;
        var expedientesUrl = 'expedientes';

        function getClave(idClave) {
            return $http.get(serviceUrl + 'claves/cabecera/' + idClave);
        }

        function getClavesInformes(idClave) {
            return $http.get(serviceUrl + 'claves/clavestmae/' + idClave);
        }

        function getCarreteras() {
            return $http.get(serviceUrl + 'carreteras');
        }

        function getTiposVia() {
            return $http.get(serviceUrl + 'tiposvia');
        }

        function getTiposObra() {
            return $http.get(serviceUrl + 'tiposobra');
        }

        function getProvincias() {
            return $http.get(serviceUrl + 'terminosmunicipales/provincias');
        }

        function getExpedientes(idClave) {
            return $http.get(serviceUrl + expedientesUrl + '/' + idClave);
        }

        function getExpedientesTitular(idTitular) {
            return $http.get(serviceUrl + expedientesUrl + '/titular/' + idTitular);
        }

        function getTitular(idTitular) {
            return $http.get(serviceUrl + 'propietarios/cabecera/' + idTitular);
        }

        function getExpediente(idExpediente) {
            return $http.get(serviceUrl + expedientesUrl + '/exp/' + idExpediente);
        }

        function deleteExpediente(idExpediente) {
            return $http.delete(serviceUrl + expedientesUrl + '/delete/' + idExpediente);
        }

        function getEstadosExpediente(idExpediente) {
            return $http.get(serviceUrl + 'estados/expediente/' + idExpediente);
        }

        function getEstados() {
            return $http.get(serviceUrl + 'estados');
        }

        function getEstadosClave(idClave) {
            return $http.get(serviceUrl + 'estados/clave/' + idClave);
        }

        function getClasesFinca() {
            return $http.get(serviceUrl + 'clasesfincas');
        }

        function getCultivosAprovechamientos() {
            return $http.get(serviceUrl + 'cultivosaprovechamientos');
        }

        function getTerminosMunicipales() {
            return $http.get(serviceUrl + 'terminosmunicipales');
        }

        function getRegistrosMunicipales() {
            return $http.get(serviceUrl + 'registrosmunicipales');
        }

        function getTerminosMunicipalesClave(idClave) {
            return $http.get(serviceUrl + 'terminosmunicipales/' + idClave);
        }

        function getClasificacionesUrbanisticas() {
            return $http.get(serviceUrl + 'clasificacionesurbanisticas');
        }

        function getTiposExpropiacion() {
            return $http.get(serviceUrl + 'tiposexpropiacion');
        }

        function getTiposPago() {
            return $http.get(serviceUrl + 'tipospago');
        }

        function getEstadosEspeciales() {
            return $http.get(serviceUrl + 'estados/especiales');
        }

        function getDerechosPropietarios() {
            return $http.get(serviceUrl + 'derechospropietarios');
        }

        function getPropietariosCopia(filtro) {
            return $http.get(serviceUrl + 'propietarios/copia/' + filtro);
        }

        function getPropietario(idPropietario) {
            return $http.get(serviceUrl + 'propietarios/' + idPropietario);
        }

        //Inicio Iker
        function getPropietarioPorTerminoMunicipal(idTerminoMunicipal) {
            return $http.get(serviceUrl + 'propietarios/terminomunicipal/' + idTerminoMunicipal);
        }

        function getExpedientesPorPropietario(idPropietario, idTerminoMunicipal, idClave) {
            return $http.get(serviceUrl + expedientesUrl + '/propietario/' + idPropietario + '/' + idTerminoMunicipal + '/' + idClave);
        }

        function getExpedientesPorAsignacion(asignacionExpediente, idTerminoMunicipal, idClave) {
            return $http.get(serviceUrl + expedientesUrl + '/asignacion/' + asignacionExpediente + '/' + idTerminoMunicipal + '/' + idClave);
        }

        function getAsignaciones(idClave) {
            return $http.get(serviceUrl + expedientesUrl + '/asignaciones/' + idClave);
        }

        function getMdbExproecon(idClave) {
            //return $http.get(serviceUrl + 'exproeconpagador/basedatos/mdb/' + idClave);

            return $http({
                method: 'get',
                url: serviceUrl + 'exproeconpagador/basedatos/mdb/' + idClave,
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/octet-stream'
                }
            });

        }

        function getDocumento(tipoDocumento, idExpediente, idClave, idEstado, fechaInforme, fechaConvocatoria, actualizarFecha) {
            return $http({
                method: 'get',
                url: serviceUrl + 'informes/documento/' + tipoDocumento + '/' + idClave + '/' + idExpediente,
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/msword'
                },
                params: {
                    idEstado: idEstado,
                    fechaInforme: $filter('date')(fechaInforme, "yyyy-MM-dd HH:mm:ss"),
                    fechaConvocatoria: $filter('date')(fechaConvocatoria, "yyyy-MM-dd HH:mm:ss"),
                    actualizarFecha: actualizarFecha
                }
            });
        }


        function getDocumentoOficio(tipoDocumento, idClave, idTerminoMunicipal, idPropietario, idEstado, fechaInforme, fechaConvocatoria, actualizarFecha) {
            return $http({
                method: 'get',
                url: serviceUrl + 'informes/oficios/' + tipoDocumento + '/' + idClave + '/' + idTerminoMunicipal + '/' + idPropietario,
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/msword'
                },
                params: {
                    idEstado: idEstado,
                    fechaInforme: $filter('date')(fechaInforme, "yyyy-MM-dd HH:mm:ss"),
                    fechaConvocatoria: $filter('date')(fechaConvocatoria, "yyyy-MM-dd HH:mm:ss"),
                    actualizarFecha: actualizarFecha
                }
            });
        }

        function getDocumentos(tipoDocumento, idsExpedientes, idEstado, fechaInforme, fechaConvocatoria, asignacion) {
            return $http({
                method: 'get',
                url: serviceUrl + 'informes/documentos/' + tipoDocumento,
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/octet-stream'
                },
                params: {
                    idsExpediente: idsExpedientes,
                    idEstado: idEstado,
                    fechaInforme: $filter('date')(fechaInforme, "yyyy-MM-dd HH:mm:ss"),
                    fechaConvocatoria: $filter('date')(fechaConvocatoria, "yyyy-MM-dd HH:mm:ss"),
                    asignacion: asignacion
                }
            });
        }

        function getDocumentoRelacionPago(tipoDocumento, idClave, idTerminoMunicipal, asignacionExpediente, importe, numFincas) {
            return $http({
                method: 'get',
                url: serviceUrl + 'informes/documento/controlexpediente/' + tipoDocumento + '/' + idClave + '/' + idTerminoMunicipal + '/' + asignacionExpediente + '/' + importe + '/' + numFincas,
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/msword'
                },
                params: {
                }
            });
        }

        function getDocumentoFirmasRecibis(tipoDocumento, idClave, idTerminoMunicipal, asignacionExpediente, importe, numFincas) {
            return $http({
                method: 'get',
                url: serviceUrl + 'informes/documento/controlexpediente/' + tipoDocumento + '/' + idClave + '/' + idTerminoMunicipal + '/' + asignacionExpediente + '/' + importe + '/' + numFincas,
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/msword'
                },
                params: {
                }
            });
        }

        function getFirmas(idClave) {
            return $http.get(serviceUrl + "firmas/" + idClave);
        }

        function getDocumentoAlmacenado(idDocumento, pertenencia) {
            if (pertenencia === 1) {
                return $http({
                    method: 'get',
                    url: serviceUrl + 'archivosfincas/plano/' + idDocumento,
                    responseType: 'arraybuffer',
                    /*headers: {
                        'Content-Type': 'application/pdf'
                    }*/
                });
            } else if (pertenencia === 2) {
                return $http({
                    method: 'get',
                    url: serviceUrl + 'archivosclaves/archivo/' + idDocumento,
                    responseType: 'arraybuffer',
                    /*headers: {
                        'Content-Type': 'application/pdf'
                    }*/
                });
            } else {
                return $http({
                    method: 'get',
                    url: serviceUrl + 'informes/documentoalmacenado/' + idDocumento,
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/msword'
                    }
                });
            }
        }

        function getListadoDocumentos(idsExpediente) {
            return $http.get(serviceUrl + 'informes/documentosobtener/' + idsExpediente);
        }

        function getListadoPropietariosCitacionAP(idClave) {
            return $http.get(serviceUrl + 'listados/listadosobtener/' + idClave);
        }

        function getKmlsPorClave(idClave) {
            return $http.get(serviceUrl + 'kmls/clave/' + idClave);
        }

        //function getExisteFecha(tipoDocumento, idExpediente, idEstado) {
        //    return $http.get(serviceUrl + 'informes/fechadocumento/' + tipoDocumento + '/' +idExpediente + '/' +idEstado);
        //}
        //Fin Iker

        function postDatosFinca(finca, idClave, idExpediente) {
            return $http.post(serviceUrl + 'fincas/post/' + idClave + '/' + idExpediente, finca);
        }

        function putDatosFinca(finca, idExpediente) {
            return $http.put(serviceUrl + 'fincas/put/' + idExpediente, finca);
        }

        function getExpedientesCopiables(filtro) {
            return $http.get(serviceUrl + 'expedientes/fincascopiables/' + filtro);
        }

        function getExpedienteCopiado(idExpediente, idClaveDestino) {
            return $http.get(serviceUrl + 'expedientes/copiar/' + idExpediente + '/' + idClaveDestino);
        }

        /*function postImagenes(idFinca,idClave,idExpediente, imagenes) {
            return $http.post(serviceUrl + 'archivosfincas/imagenes/post/' + idFinca + '/' + idClave + '/' + idExpediente, imagenes,
                                                     {
                                                         transformRequest: angular.identity,
                                                         headers: { 'Content-Type': undefined }
                                                     });


        }*/
        //Inicio Iker
        function postDocumentos(tipoDocumento, idClave, idExpediente, documentos) {
            return $http({
                method: 'POST',
                url: serviceUrl + 'informes/documentoescaneado/' + tipoDocumento + '/' + idClave + '/' + idExpediente,
                data: documentos,
                headers: { 'Content-Type': undefined }
            });
        }

        function deleteArchivoExpediente(idArchivoExpediente) {
            return $http.delete(serviceUrl + 'informes/deletearchivo/' + idArchivoExpediente);
        }

        function eliminarArchivos(archivosEliminar) {
            return $http.delete(serviceUrl + 'informes/eliminar/' + archivosEliminar, { headers: { 'Content-Type': 'application/json' } })
                .catch(function (response) {
                    alert('Error');
                });
        }
        //Fin Iker

        function postPlanos(idFinca, idClave, idExpediente, planos) {
            return $http({
                method: 'POST',
                url: serviceUrl + 'archivosfincas/planos/post/' + idFinca + '/' + idClave + '/' + idExpediente,
                data: planos,
                headers: { 'Content-Type': undefined }
            })
        }

            /*.then(
                function (response) {
                    if (typeof response.data === 'string') {
                        alert("servicio bien");
                        return response.data;
                    } else {
                        return $q.reject(response.data);
                    }
                },
                function (response) {
                    return $q.reject(response.data);
                }
            ).error( function (error){
                return $q.reject(error);
            })*/
        

        function getPlanos(idExpediente) {
            return $http.get(serviceUrl + 'archivosfincas/planos/' + idExpediente);
        }

        function getPlanosClave(idExpediente) {
            return $http.get(serviceUrl + 'archivosclaves/planos/' + idExpediente);
        }

        function getImagenesExpediente(idExpediente) {
            return $http.get(serviceUrl + 'archivosfincas/imagenes/' + idExpediente);
        }

        function postImagen(idFinca, idClave, idExpediente, imagen) {
            return $http({
                method: 'POST',
                url: serviceUrl + 'archivosfincas/imagenes/post/' + idFinca + '/' + idClave + '/' + idExpediente,
                data: imagen,
                headers: { 'Content-Type': 'multipart/form-data' }
            });
        }

        function postImagenes(idFinca, idClave, idExpediente, imagenes) {
            return $http({
                method: 'POST',
                url: serviceUrl + 'archivosfincas/imagenes/post/' + idFinca + '/' + idClave + '/' + idExpediente,
                data: imagenes,
                headers: { 'Content-Type': undefined }
            });
        }

        function deleteArchivoFinca(idExpediente, idArchivoFinca, nombreArchivoFinca) {
            return $http.delete(serviceUrl + 'archivosfincas/delete/' + idExpediente + '/' + idArchivoFinca + '/' + nombreArchivoFinca);
        }

        function putDatosPropietariosFinca(propietariosFinca, idExpediente) {
            return $http.put(serviceUrl + 'propietariosfincas/put/' + idExpediente, propietariosFinca);
        }

        function postDatosPropietariosFinca(propietariosFinca, idClave, idExpediente) {
            return $http.post(serviceUrl + 'propietariosfincas/post/' + idClave + '/' + idExpediente, propietariosFinca);
        }

        function getCuadroPreciosClave(idClave) {
            return $http.get(serviceUrl + 'elementoscuadropreciosclave/' + idClave);
        }

        function putExpediente(expediente, idExpediente) {
            return $http.put(serviceUrl + 'expedientes/put/' + idExpediente, expediente);
        }

        function postExpediente(expediente, idClave, idExpediente) {
            return $http.post(serviceUrl + 'expedientes/post/' + idClave + '/' + idExpediente, expediente);
        }

        function getValoracion(idExpediente, idEstado) {
            return $http.get(serviceUrl + 'valoracion/' + idExpediente + '/' + idEstado);
        }

        function putValoracion(datosValoracionExpediente, idExpediente) {
            return $http.put(serviceUrl + 'valoracion/put/' + idExpediente, datosValoracionExpediente);
        }

        function postValoracion(datosValoracionExpediente, idClave, idExpediente) {
            return $http.post(serviceUrl + 'valoracion/post/' + idClave + '/' + idExpediente, datosValoracionExpediente);
        }

        //Listados
        function getListado(opcion, idClave, filtroIDs, fecha) {
            var urlListado = "";
            urlListado = 'listados/' + opcion;

            return $http({
                method: 'get',
                url: serviceUrl + urlListado,
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/octet-stream'
                },
                params: {
                    idClave: idClave,
                    filtroIDs: filtroIDs,
                    fecha: (fecha === null) ? "" : ($filter('date')(fecha, "yyyy-MM-dd")).toString()
                }
            });
        }

        //Inicio Prueba Obtener Fincas, Propietarios y Resumen por Expediente
        function getFincasPorExpediente(idExpediente) {
            return $http.get(serviceUrl + 'fincas/expediente/' + idExpediente);
        }

        function getPropietariosPorExpediente(idExpediente) {
            return $http.get(serviceUrl + 'propietariosfincas/expediente/' + idExpediente);
        }

        function getPropietariosPorMunicipioOficio(idMunicipio, idClave) {
            return $http.get (serviceUrl + 'propietarios/terminosmunicipalesfinca/' + idMunicipio + '/' + idClave);
        }

        function getResumenExpediente(idExpediente) {
            return $http.get(serviceUrl + expedientesUrl + '/expediente/resumen/' + idExpediente);
        }
        //Fin Prueba Obtener Fincas, Propietarios y Resumen por Expediente

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    return response.ModelState[key][i];
                }
            }
            return errores[0];
        }

        function createAlbumImagenes(idFinca, idClave, idExpediente) {
            return $http({
                method: 'get',
                url: serviceUrl + 'archivosfincas/albumfotos/' + idFinca + '/' + idClave + '/' + idExpediente,
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/msword'
                },
            });
        }

        return {
            getClave: getClave,
            getClavesInformes: getClavesInformes,
            getCarreteras: getCarreteras,
            getProvincias: getProvincias,
            getTiposVia: getTiposVia,
            getTiposObra: getTiposObra,
            getExpedientes: getExpedientes,
            getExpedientesTitular: getExpedientesTitular,
            getTitular: getTitular,
            getExpediente: getExpediente,
            deleteExpediente: deleteExpediente,
            getEstadosExpediente: getEstadosExpediente,
            getEstados: getEstados,
            getEstadosClave : getEstadosClave,
            getClasesFinca: getClasesFinca,
            getCultivosAprovechamientos: getCultivosAprovechamientos,
            getTerminosMunicipales: getTerminosMunicipales,
            getRegistrosMunicipales: getRegistrosMunicipales,
            getTerminosMunicipalesClave: getTerminosMunicipalesClave,
            getClasificacionesUrbanisticas: getClasificacionesUrbanisticas,
            getTiposExpropiacion: getTiposExpropiacion,
            getTiposPago: getTiposPago,
            getEstadosEspeciales: getEstadosEspeciales,
            getDerechosPropietarios: getDerechosPropietarios,
            getPropietariosCopia: getPropietariosCopia,
            getPropietario: getPropietario,
            //Inicio Iker
            getPropietarioPorTerminoMunicipal: getPropietarioPorTerminoMunicipal,
            getPropietariosPorMunicipioOficio: getPropietariosPorMunicipioOficio,
            getExpedientesPorPropietario: getExpedientesPorPropietario,
            getExpedientesPorAsignacion: getExpedientesPorAsignacion,
            getAsignaciones: getAsignaciones,
            getMdbExproecon: getMdbExproecon,
            getDocumento: getDocumento,
            getDocumentos: getDocumentos,
            getDocumentoRelacionPago: getDocumentoRelacionPago,
            getDocumentoFirmasRecibis: getDocumentoFirmasRecibis,
            getFirmas: getFirmas,
            getListadoDocumentos: getListadoDocumentos,
            getDocumentoAlmacenado: getDocumentoAlmacenado,
            getKmlsPorClave: getKmlsPorClave,
            //getExisteFecha: getExisteFecha,
            //Fin Iker
            postDatosFinca: postDatosFinca,
            putDatosFinca: putDatosFinca,
            getExpedientesCopiables: getExpedientesCopiables,
            getExpedienteCopiado: getExpedienteCopiado,
            postPlanos: postPlanos,
            getPlanos: getPlanos,
            getPlanosClave: getPlanosClave,
            getImagenesExpediente: getImagenesExpediente,
            postImagen: postImagen,
            postImagenes:postImagenes,
            //Iker
            postDocumentos: postDocumentos,
            deleteArchivoExpediente: deleteArchivoExpediente,
            //Fin Iker
            deleteArchivoFinca: deleteArchivoFinca,
            putDatosPropietariosFinca: putDatosPropietariosFinca,
            postDatosPropietariosFinca: postDatosPropietariosFinca,
            getCuadroPreciosClave: getCuadroPreciosClave,
            putExpediente: putExpediente,
            postExpediente: postExpediente,
            getValoracion: getValoracion,
            putValoracion: putValoracion,
            postValoracion: postValoracion,
            getListado: getListado,
            //Inicio Prueba Obtener Fincas, Propietarios y Resumen por Expediente
            getFincasPorExpediente: getFincasPorExpediente,
            getPropietariosPorExpediente: getPropietariosPorExpediente,
            getResumenExpediente: getResumenExpediente,            
            //Fin Prueba Obtener Fincas, Propietarios y Resumen por Expediente
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState,
            getDocumentoOficio: getDocumentoOficio,
            eliminarArchivos: eliminarArchivos,
            createAlbumImagenes: createAlbumImagenes
        }
    }]);
