angular.module('usuarios', [])
       .controller('usuarioController', ['$scope', '$routeParams', '$location', 'usuariosService', function ($scope, $routeParams, $location, usuariosService) {
           usuariosService.getConfig()
                             .success(function (data) {
                                 $scope.usuario = data;
                             });

           $scope.editarUsuario = function () {
               usuariosService.put($scope.usuario).success(function (result) {
                   alert(result);

               }).error(function (response) {
                   $scope.error = usuariosService.obtenerPrimerErrorModelState(response);
                   alert($scope.error);
               });
           }

           $scope.cambiarPassword = function () {
               $location.path("/usuario/password/" + $scope.usuario.Id);
           }
       }]);