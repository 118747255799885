angular.module('login', [])
       .controller('loginController', ['$scope', '$rootScope', '$location', '$http', '__env', 'authenticationInfo', 'authenticationService', 'onlineStatus', function ($scope, $rootScope, $location, $http, __env, authenticationInfo, authenticationService, onlineStatus) {
            var serviceUrl = __env.apiUrl;
           $scope.IsOnLine = onlineStatus.isOnline();
           $scope.authentication = authenticationInfo;
           $scope.usuario = authenticationInfo.userName;
           $rootScope.bodylayout = "login";

           if (authenticationInfo.isAuthenticated) {
            $rootScope.bodylayout = "logged";
           } else {
            $rootScope.bodylayout = "login";
           }

           $scope.login = function () {

               return authenticationService.login({
                   userName: $scope.usuario,
                   password: $scope.password
               }).then(function () {
                   $scope.authentication = authenticationInfo;
                   $rootScope.bodylayout = "logged";
               });
           }

           $scope.logout = function () {
              $rootScope.bodylayout = "login";
               return authenticationService.logout();
           }

           $scope.modificarDatos = function () {
               $location.path('/usuario/config');
           }

           $scope.backupBD = function () {
               $http.get(serviceUrl + 'bd/backup', {
                   responseType: 'arraybuffer',
                   headers: {
                       'Content-Type': 'application/octet-stream'
                   }})
                   .success(function (data, status, headers) {
                       var filename = headers('x-filename');
                       var contentType = 'application/octet-stream';

                       var blob = new Blob([data],
                       { type: contentType });
                       if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                           window.navigator.msSaveBlob(blob, filename);
                       else //Resto
                       {
                           var downloadLink = document.createElement("a");
                           var url = window.URL.createObjectURL(blob);
                           downloadLink.setAttribute("target", "_blank");
                           downloadLink.setAttribute("href", url);
                           downloadLink.setAttribute("download", filename);
                           document.body.appendChild(downloadLink);
                           downloadLink.click();
                           document.body.removeChild(downloadLink);
                       }
                   })
                    .error(function (response) {
                        alert('Error al obtener el backup de la base de datos.');
                });
           }
       }]);
