angular
    .module('registrosMunicipalesAdmin')
    .factory('registrosMunicipalesAdminService', ['$http', '$q', '__env', function registrosMunicipalesAdminService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var registrosMunicipalesUrl = 'registrosmunicipales';

        function getRegistrosMunicipales() {
            return $http.get(serviceUrl + registrosMunicipalesUrl + '/admin');
        }

        function getTerminosMunicipales() {
            return $http.get(serviceUrl + 'terminosmunicipales');
        }

        function putRegistrosMunicipales(registrosMunicipales) {
            return $http.put(serviceUrl + registrosMunicipalesUrl + '/guardar', registrosMunicipales);
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    errores.push(response.ModelState[key][i]);
                }
            }
            return errores[0];
        }

        return {
            getRegistrosMunicipales:getRegistrosMunicipales,
            getTerminosMunicipales: getTerminosMunicipales,
            putRegistrosMunicipales: putRegistrosMunicipales,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState
        }
    }]);
