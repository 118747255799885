angular
    .module('plantillasWordAdmin')
    .factory('plantillasWordAdminService', ['$http', '$q', '__env', function plantillasWordAdminService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var plantillaswordUrl = 'plantillasword';

        function getPlantillasWord() {
            return $http.get(serviceUrl + plantillaswordUrl);
        }

        function getPlantillaWord(plantillaword) {
            return $http({
                method: 'get',
                url: serviceUrl + plantillaswordUrl + '/download/' + plantillaword,
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/msword'
                }
            });
        }

        function postPlantillaWord(plantilla) {
            return $http({
                method: 'POST',
                url: serviceUrl + plantillaswordUrl + '/guardar/',
                data: plantilla,
                headers: { 'Content-Type': 'multipart/form-data' }
            });
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    errores.push(response.ModelState[key][i]);
                }
            }
            return errores[0];
        }

        return {
            getPlantillasWord: getPlantillasWord,
            getPlantillaWord: getPlantillaWord,
            postPlantillaWord: postPlantillaWord,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState
        }
    }]);
