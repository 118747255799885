angular
    .module('claves')
    .factory('clavesService', ['$http', '$q', '__env', function clavesService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var clavesUrl = 'claves';

        function getClaves(pageSize, pageNumber, codigoClave, alias, proyecto, fechaInicioExpediente) {
            var paginacion = "";
            if (typeof pageSize !== "undefined" && typeof pageNumber !== "undefined") {
                paginacion = '/' + pageSize + '/' + pageNumber;
            }
            var criteriosBusqueda = "";
            criteriosBusqueda += codigoClave.replace("/","__") + ",";
            criteriosBusqueda += alias.replace("/", "__") + ",";
            criteriosBusqueda += proyecto.replace("/", "__") + ",";
            criteriosBusqueda += fechaInicioExpediente.replace("/", "__");

            return $http.get(serviceUrl + clavesUrl + '/' + criteriosBusqueda + paginacion);
        }

        function getTitulares(pageSize, pageNumber, nombre, apellido1, apellido2, clave, nif) {
            var paginacion = "";
            if (typeof pageSize !== "undefined" && typeof pageNumber !== "undefined") {
                paginacion = '/' + pageSize + '/' + pageNumber;
            }
            var criteriosBusqueda = "";
            criteriosBusqueda += nombre.replace("/", "__") + ",";
            criteriosBusqueda += apellido1.replace("/", "__") + ",";
            criteriosBusqueda += apellido2.replace("/", "__") + ",";
            criteriosBusqueda += clave.replace("/", "__") + ",";
            criteriosBusqueda += nif.replace("/", "__");

            return $http.get(serviceUrl + 'propietarios/' + criteriosBusqueda + paginacion);
        }

        function getClavesAsociar(idClaveActual) {
            return $http.get(serviceUrl + clavesUrl + "/asociar/" + idClaveActual);
        }

        function getCarreteras() {
            return $http.get(serviceUrl + 'carreteras');
        }

        function getAutoridades() {
            return $http.get(serviceUrl + 'autoridades');
        }

        function getTerminosMunicipales() {
            return $http.get(serviceUrl + 'terminosmunicipales');
        }

        function getTiposObra() {
            return $http.get(serviceUrl + 'tiposobra');
        }

        function getTiposvia() {
            return $http.get(serviceUrl + 'tiposvia');
        }

        function getLegislacionesAplicables() {
            return $http.get(serviceUrl + 'legislacionesaplicables');
        }

        function getLeyesAcompañamiento() {
            return $http.get(serviceUrl + 'leyesacompañamiento');
        }

        function getClave(idClave) {
            return $http.get(serviceUrl + clavesUrl + '/' + idClave);
        }

        //Inicio Iker
        function getKmls() {
            return $http.get(serviceUrl + 'kmls/');
        }
        //Fin Iker

        //Cuadro de Precios
        var elementoscuadropreciosUrl = 'elementoscuadropreciosclave';

        function getElementosCuadroPreciosClave(idClave) {
            return $http.get(serviceUrl + elementoscuadropreciosUrl + '/clave/' + idClave);
        }
        function getConceptos() {
            return $http.get(serviceUrl + elementoscuadropreciosUrl + '/conceptos');
        }
        function getClasificaciones() {
            return $http.get(serviceUrl + elementoscuadropreciosUrl + '/clasificaciones');
        }
        function getUnidadesElementos() {
            return $http.get(serviceUrl + elementoscuadropreciosUrl + '/unidadeselementos');
        }
        function putElementosCuadroPreciosClave(idClave, elementosCuadroPrecios) {
            return $http.put(serviceUrl + elementoscuadropreciosUrl + '/guardar/' + idClave, elementosCuadroPrecios);
        }
        //FIN Cuadro de Precios

        //Firmas
        var firmasUrl = 'firmas';

        function getFirmas(idClave) {
            return $http.get(serviceUrl + firmasUrl + '/' + idClave);
        }
        function guardarFirmas(firmas) {
            return $http.post(serviceUrl + firmasUrl + '/guardar', firmas);
        }
        //FIN Firmas

        //Planos
        function postArchivos(idClave, archivos) {
            return $http({
                method: 'POST',
                url: serviceUrl + 'archivosclaves/post/' + idClave,
                data: archivos,
                headers: { 'Content-Type': undefined }
            })
            /*.then(
                function (response) {
                    if (typeof response.data === 'string') {
                        alert("servicio bien");
                        return response.data;
                    } else {
                        return $q.reject(response.data);
                    }
                },
                function (response) {
                    return $q.reject(response.data);
                }
            ).error( function (error){
                return $q.reject(error);
            })*/;
        }

        function getArchivos(idClave) {
            return $http.get(serviceUrl + 'archivosclaves/' + idClave);
        }

        function deleteArchivo(idArchivoClave) {
            return $http.delete(serviceUrl + 'archivosclaves/delete/' + idArchivoClave);
        }

        function getArchivo(idArchivo) {
            return $http({
                method: 'get',
                url: serviceUrl + 'archivosclaves/archivo/' + idArchivo,
                responseType: 'arraybuffer',
                /*headers: {
                    'Content-Type': 'application/pdf'
                }*/
            });
        }

        //FIN Planos

        function post(clave) {
            return $http.post(serviceUrl + clavesUrl + '/post', clave);
        }
        function put(clave) {
            return $http.put(serviceUrl + clavesUrl + '/put', clave);
        }
        /*function autoridadesPut(autoridades) {
            return $http.put(serviceUrl + '/autoridades/put', autoridades);
        }*/

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    return response.ModelState[key][i];
                }
            }
            return errores[0];
        }

        return {
            getClaves: getClaves,
            getTitulares: getTitulares,
            getClavesAsociar:getClavesAsociar,
            getCarreteras:getCarreteras,
            getAutoridades: getAutoridades,
            getTerminosMunicipales: getTerminosMunicipales,
            getTiposObra: getTiposObra,
            getTiposvia: getTiposvia,
            getLegislacionesAplicables: getLegislacionesAplicables,
            getLeyesAcompañamiento:getLeyesAcompañamiento,
            getClave: getClave,
            //Inicio Iker
            getKmls: getKmls,
            //Fin Iker
            getElementosCuadroPreciosClave: getElementosCuadroPreciosClave,
            getConceptos: getConceptos,
            getClasificaciones: getClasificaciones,
            getUnidadesElementos: getUnidadesElementos,
            putElementosCuadroPreciosClave: putElementosCuadroPreciosClave,
            getFirmas: getFirmas,
            guardarFirmas: guardarFirmas,
            postArchivos: postArchivos,
            getArchivos: getArchivos,
            deleteArchivo: deleteArchivo,
            getArchivo:getArchivo,
            post:post,
            put: put,
            //autoridadesPut: autoridadesPut,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState
        }
    }]);
