angular.module('expedientes')
        .controller('expedienteController', ['onlineStatus', '$rootScope', '$scope', '$location', '$route', '$routeParams', 'authenticationInfo', 'expedientesService', '$filter', 'Utilidades', 'uiGmapGoogleMapApi', '__env', function (onlineStatus, $rootScope, $scope, $location, $route, $routeParams, authenticationInfo, expedientesService, $filter, Utilidades, uiGmapGoogleMapApi, __env) {
            var serviceUrl = __env.apiUrl;
            $scope.dateOptions = Utilidades.getDatePickerOptions();

            //Comprobar la conexion a internet para mostrarlo
            $scope.onlineStatus = onlineStatus;
            $scope.authentication = authenticationInfo;
            $scope.estado = null;
            $scope.$watch('onlineStatus.isOnline()', function (online) {
                $scope.online_status_string = online ? 'online' : 'offline';
                $scope.estado = $scope.online_status_string;
            });

            if ($routeParams.tit != null) {
                expedientesService.getClave($routeParams.id)
                    .success(function (data) {
                        $rootScope.clave = data;
                    });

            }
            $scope.clave = $rootScope.clave;

            $scope.ExpedienteId = $routeParams.id;
            $scope.Copiado = $routeParams.copiado;
            $scope.pestanyavisible = ($scope.ExpedienteId > 0 && $scope.Copiado != 's') ? 'resumen' : 'finca';
            $scope.GISiniciado = 0;

            $scope.ImporteValoracion = 0;
            $scope.LimiteConformidad = 0;

            $scope.numberFormat = function (value) {
                return Utilidades.numberFormat(value);
            }

            function tieneValoracion(estado) {
                return estado.Valoracion;
            }
            $scope.estadoActualInicialObtener = function () {
                // Cogemos el último estado que tenga valoración.
                var estadosConValoracion = $scope.expediente.Estados.filter(tieneValoracion);
                if(estadosConValoracion.length > 0)
                    return estadosConValoracion[estadosConValoracion.length - 1].Id;
            }

            $scope.estadoHayDP = false;
            $scope.estadoHayDPObtener = function () {
                for (var i = 0; i < $scope.expediente.Estados.length; i++) {
                    if ($scope.expediente.Estados[i].Id == 4) {
                        return true;
                        break;
                    }
                }
                return false;
            }

            $scope.estadoActualizar = function (id) {
                $scope.EstadoActual = id;
                $scope.CodigoEstadoActual = $scope.codigoEstadoActualObtener();
                if ($scope.pestanyavisible == 'valoracion') {
                    $scope.valoracionInicializar();
                }
            }

            //Finca
            $scope.seleccionarExpediente = function (idExpediente) {
                expedientesService.getExpedienteCopiado(idExpediente, $routeParams.idClave)
                       .success(function (data) {
                           $location.path('/expedientes/' + $routeParams.idClave + "/" + data + "/s");
                       })
                        .error(function (response) {
                            alert('Error al copiar finca');
                        });
                $('#myModalFincaCopiar').modal('hide');
            }

            $scope.idNuevaSubparcela = 0;
            $scope.nuevaSubparcela = function () {
                var nombreSubparcela = null;
                if ($scope.expediente.Finca.Subparcelas == null) $scope.expediente.Finca.Subparcelas = [];
                var numSubparcelasFinca = $scope.expediente.Finca.Subparcelas.length;
                var subparcelasNombres = 'abcdefghijklmnopqrstuvwxyz';
                nombreSubparcela = subparcelasNombres.substr(numSubparcelasFinca, 1);
                subparcelaNombreAsignado = $filter('filter')($scope.expediente.Finca.Subparcelas, { NombreSubparcela: nombreSubparcela });
                if (subparcelaNombreAsignado.length > 0) nombreSubparcela = '';
                $scope.idNuevaSubparcela--;
                var subparcela = {
                    Id: $scope.idNuevaSubparcela,
                    NombreSubparcela: nombreSubparcela,
                    CultivoAprovechamientoId: null,
                    Superficie: "",
                };
                $scope.expediente.Finca.Subparcelas.push(subparcela);
            }
            $scope.eliminarSubparcela = function (index) {
                $scope.expediente.Finca.Subparcelas.splice(index, 1);
            }

            $scope.idNuevo = 0;
            $scope.nuevoRegistro = function () {
                $scope.idNuevo--;
                var registro = {
                    Id: $scope.idNuevo,
                    Superficie: null,
                    Tomo: null,
                    Libro: null,
                    Folio: null,
                    NumRegistroPropiedad: null,
                    Inscripcion: null,
                    Fecha: null,
                    EnRegistro: {
                        Id: null,
                        Municipio: null,
                        Provincia: null,
                        ProvinciaId: null
                    },
                    EnRegistroId: null,
                    FincaId: $scope.expediente.FincaId,
                    NumRegistroFinca: null
                };
                if ($scope.expediente.Finca.Registros == null) $scope.expediente.Finca.Registros = [];
                $scope.expediente.Finca.Registros.push(registro);
            }
            $scope.eliminarRegistro = function (index) {
                $scope.expediente.Finca.Registros.splice(index, 1);
            }
            //Fin Finca

            //Propietarios
            $scope.obtenerPropietariosFiltro = function () {
                var filtro = ($scope.filtroPropietarios.Nombre == undefined ? "" : $scope.filtroPropietarios.Nombre) + "," +
                             ($scope.filtroPropietarios.Apellido1 == undefined ? "" : $scope.filtroPropietarios.Apellido1) + "," +
                             ($scope.filtroPropietarios.Apellido2 == undefined ? "" : $scope.filtroPropietarios.Apellido2) + "," +
                             ($scope.filtroPropietarios.NIF == undefined ? "" : $scope.filtroPropietarios.NIF);


                expedientesService.getPropietariosCopia(filtro)
                       .success(function (data) {
                           $scope.propietarios = data;
                       });
            };

            $scope.obtenerFincasFiltro = function () {
                var filtro = ($scope.filtroFincas.Clave == undefined ? "" : $scope.filtroFincas.Clave) + "," +
                             ($scope.filtroFincas.TerminoMunicipal == undefined ? "" : $scope.filtroFincas.TerminoMunicipal) + "," +
                             ($scope.filtroFincas.Finca == undefined ? "" : $scope.filtroFincas.Finca) + "," +
                             ($scope.filtroFincas.Poligono == undefined ? "" : $scope.filtroFincas.Poligono) + "," +
                             ($scope.filtroFincas.Parcela == undefined ? "" : $scope.filtroFincas.Parcela);


                expedientesService.getExpedientesCopiables(filtro)
                       .success(function (data) {
                           $scope.expedientescopiables = data;
                       });
            };


            $scope.NumPropietario = -1;
            $scope.numPropietarioSeleccionar = function (index) {
                $scope.NumPropietario = index;
            };
            $scope.seleccionarPropietario = function (idPropietario) {
                expedientesService.getPropietario(idPropietario)
                       .success(function (data) {
                           var idAsignado = $scope.expediente.PropietariosFinca[$scope.NumPropietario].Id;
                           $scope.expediente.PropietariosFinca[$scope.NumPropietario] = data;
                           //$scope.expediente.PropietariosFinca[$scope.NumPropietario].Id = idAsignado;
                           $scope.expediente.PropietariosFinca[$scope.NumPropietario].NumPropietario = $scope.NumPropietario + 1;
                           $scope.expediente.PropietariosFinca[$scope.NumPropietario].DerechoPropietarioId = $scope.resumen.FincaArrendada ? 3 : 1;
                       })
                        .error(function (response) {
                            alert('Error al copiar propietario');
                        });
                $('#myModalPropietarioCopiar').modal('hide');
            }

            $scope.idNuevoProp = 0;

            $scope.nuevoPropietario = function () {
                $scope.idNuevoProp--;
                var propietario = {
                    NumPropietario: $scope.expediente.PropietariosFinca.length + 1,
                    Id: $scope.idNuevoProp,
                    Nombre: null,
                    Apellido1: null,
                    Apellido2: null,
                    NIF: null,
                    Domicilio: null,
                    CP: null,
                    TerminoMunicipalId: null,
                    Telefono: null,
                    Movil: null,
                    Email: null,
                    CuentaIBAN: null,
                    Genero: null,
                    Representante: null,
                    DerechoPropietarioId: $scope.resumen.FincaArrendada ? 3 : 1,  
                    EsTitularCatastral: true,
                    EsTitularReal: true
                }
                $scope.expediente.PropietariosFinca.push(propietario);
            }

           $scope.propietarioEliminar = function (index) {
               $scope.expediente.PropietariosFinca.splice(index, 1);
           }
           $scope.representanteEliminar = function (index) {
               $scope.expediente.PropietariosFinca[index].Representante = null;
               $scope.expediente.PropietariosFinca[index].HayRepresentante = false;
           }

           $scope.representanteActualizar = function (index) {
               if (!$scope.expediente.PropietariosFinca[index].HayRepresentante) {
                   $scope.expediente.PropietariosFinca[index].Representante = null;
               } else {
                   var representante = {
                       Id: 0,
                       Nombre: null,
                       Apellido1: null,
                       Apellido2: null,
                       NIFCIF: null,
                       Domicilio: null,
                       CP: null,
                       Municipio: {
                           Id: 0,
                           Municipio: null,
                           Provincia: null,
                           ProvinciaId: null
                       },
                       TerminoMunicipalId: null,
                       Empresa: null,
                       Telefono: null,
                       Movil: null,
                       Email: null,
                       Genero: null,
                       PropietarioId: $scope.expediente.PropietariosFinca[index].Id
                   }
                   $scope.expediente.PropietariosFinca[index].Representante = representante;
                   $scope.expediente.PropietariosFinca[index].HayRepresentante = true;
               }
           }
           $scope.LetraNIFPropietarioCalcular = function (indice) {
               var nif = $scope.expediente.PropietariosFinca[indice].NIF;
               if (nif.length == 8 && !(isNaN(nif))) {
                   nif = nif + "TRWAGMYFPDXBNJZSQVHLCKE".charAt(nif % 23);
                   $scope.expediente.PropietariosFinca[indice].NIF = nif;
               }
           }
           $scope.LetraNIFRepresentanteCalcular = function (indice) {
               var nif = $scope.expediente.PropietariosFinca[indice].Representante.NIFCIF;
               if (nif.length == 8 && !(isNaN(nif))) {
                   nif = nif + "TRWAGMYFPDXBNJZSQVHLCKE".charAt(nif % 23);
                   $scope.expediente.PropietariosFinca[indice].Representante.NIFCIF = nif;
               }
           }
           //Fin Propietarios

            //Expediente
            $scope.APCollapsed = false;
            //Fin Expediente

            //Valoración
            $scope.ImporteTotalDPValoracion = 0;
            $scope.OrdinalValoracionActual = 0;

            $scope.codigoEstadoActualObtener = function () {
                for (var i = 0; i < $scope.expediente.Estados.length; i++) {
                    if ($scope.expediente.Estados[i].Id == $scope.EstadoActual) {
                        $scope.expediente.DepositoPrevio
                        return $scope.expediente.Estados[i].Codigo;
                        break;
                    }
                }
            }

            $scope.SumaAfeccion5Calcular = function () {
                $scope.Afeccion5Resultante = Utilidades.numberFormat(ObtenerTotalAplicarAfeccion5Actual()) * 0.05;
                $scope.Afeccion5Resultante = parseFloat($scope.Afeccion5Resultante.toFixed(2));
                $scope.SumaAfeccion5 = Utilidades.numberFormat(ObtenerImporteValoracionActual()) + $scope.Afeccion5Resultante;
                $scope.datoExpedienteActualizar();
            }

            function ObtenerTotalAplicarAfeccion5Actual() {
                return ObtenerTotalAplicarAfeccion5PorEstado($scope.CodigoEstadoActual);
            }
            
            function ObtenerTotalAplicarAfeccion5PorEstado(codigo) {
                switch (codigo) {
                    case 'TS':
                        return $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].TotalAplicarAfeccion5_TS;
                    case 'TSJA':
                        return $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].TotalAplicarAfeccion5_TSJA;
                    case 'RJP':
                        return $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].TotalAplicarAfeccion5_RJP;
                    case 'HAA':
                        return $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].TotalAplicarAfeccion5;
                    case 'MA':
                    case 'DP IRO':
                        return "0";
                }
            }

            function ObtenerImporteValoracionActual() {
                return ObtenerImporteValoracionEstado($scope.CodigoEstadoActual);
            }

            function ObtenerImporteValoracionEstado(codigo)
            {
                switch (codigo) {
                    case 'TS':
                        return $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ImporteTS;
                    case 'TSJA':
                        return $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ImporteTSJA;
                    case 'RJP':
                        return $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ImporteRJP;
                    case 'HAA':
                        return $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ImporteHAA;
                    case 'MA':
                        return $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ImporteMA;
                    case 'DP IRO':
                        return $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ImporteTotalDPValoracion;
                }
            }
           
            $scope.DepositoPrevioCalcular = function () {
                var importeDP = (Utilidades.numberFormat($scope.expediente.Finca.Finca.ValorCatastral) * (Utilidades.numberFormat($scope.expediente.SuperficieAExpropiarPrevia))) / Utilidades.numberFormat($scope.expediente.Finca.Finca.SuperficieTotal);
                importeDP = parseFloat(importeDP.toFixed(2));

                if ($scope.expediente.Finca != "") {
                    if (esFincaArrendada($scope.expediente.Finca))
                        importeDP = 0;
                }

               if ($scope.datosValoracionExpediente != null && $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual] != null)
                   $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].DepositoPrevio = $filter('currency')(importeDP);
            }
            $scope.datosValoracionObtener = function (idEstado) {
                if ($scope.ExpedienteId > 0) {
                    expedientesService.getValoracion($scope.ExpedienteId, idEstado)
                            .success(function (data) {
                                $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual] = data;
                                if ($scope.CodigoEstadoActual == "DP IRO") {
                                    if ($scope.expediente.Finca != "") {
                                        $scope.DepositoPrevioCalcular();
                                        $scope.datoExpedienteActualizar();
                                    }
                                }
                                else if (hayQueCalcularSumaAfeccion5($scope.CodigoEstadoActual)) {
                                    $scope.SumaAfeccion5Calcular();
                                }
                                else {
                                    $scope.datoExpedienteActualizar();
                                }
                            });
                }
            }
            $scope.datoExpedienteActualizar = function () {
                var valoracionServidumbreSubterranea = "0";
                var valoracionServidumbreAerea = "0";
                $scope.datosDPARestar = 0;
                var datosValoracion = $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual];
                switch ($scope.CodigoEstadoActual) {
                    case 'MA':
                        $scope.expediente.ImporteMA = ($scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ImporteMA);
                        $scope.expediente.ImporteMA = $scope.expediente.ImporteMA + " €";
                        break;
                    case 'COT':
                        $scope.expediente.ImporteCOT = ($scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ImporteCOT);
                        $scope.expediente.ImporteCOT = $scope.expediente.ImporteCOT + " €";
                        break;
                    case 'DP IRO':
                        $scope.expediente.DepositoPrevio = $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].DepositoPrevio;
                        $scope.expediente.ImporteAdicionalDP = $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ImporteAdicionalDP;
                        $scope.expediente.ImporteIndemnizacionPerjuicios = $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ImporteIndemnizacionPerjuicios;
                       
                        var importeDPCapitalizacion = 0.0;
                        if ($scope.clave.TipoCapitalizacionDP){
                            importeDPCapitalizacion = toNumber($scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].RentaPorcionFincaAfectada) / ($scope.clave.TipoCapitalizacionDP / 100.0);
                        }

                        $scope.expediente.ImporteDPCapitalizacion = importeDPCapitalizacion.toFixed(2).replace(".", ",");
                        if (!esFincaArrendada($scope.expediente.Finca)) {
                            
                            const valorCatastral = toNumber($scope.expediente.Finca.Finca.ValorCatastral);
                            const superficieTotal = toNumber($scope.expediente.Finca.Finca.SuperficieTotal);
                            const servidumbreSubterranea = toNumber($scope.expediente.ServidumbreSubterranea);
                            const servidumbreAerea = toNumber($scope.expediente.ServidumbreAerea);

                            valoracionServidumbreSubterranea = (valorCatastral * servidumbreSubterranea * 0.6) / superficieTotal;
                            valoracionServidumbreAerea = (valorCatastral * servidumbreAerea * 0.4) / superficieTotal;

                            valoracionServidumbreSubterranea = valoracionServidumbreSubterranea.toFixed(2).replace(".", ",");
                            valoracionServidumbreAerea = valoracionServidumbreAerea.toFixed(2).replace(".", ",");
                        }
                        break;
                    case 'HAP':
                        $scope.expediente.ImporteHAP = ($scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ImporteHAP);
                        $scope.expediente.ImporteHAP = $scope.expediente.ImporteHAP + " €";
                        break;
                    case 'HAA':
                        $scope.expediente.ImporteHAA = ($scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ImporteHAA);
                        $scope.expediente.ImporteHAA = $scope.expediente.ImporteHAA + " €";
                        $scope.expediente.TotalAplicarAfeccion5 = $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].TotalAplicarAfeccion5;
                        break;
                    case 'RJP':
                        $scope.expediente.ImporteRJP = ($scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ImporteRJP);
                        $scope.expediente.ImporteRJP = $scope.expediente.ImporteRJP + " €";
                        $scope.expediente.TotalAplicarAfeccion5_RJP = $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].TotalAplicarAfeccion5_RJP;
                        break;
                    case 'TSJA':
                        $scope.expediente.ImporteTSJA = ($scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ImporteTSJA);
                        $scope.expediente.ImporteTSJA = $scope.expediente.ImporteTSJA + " €";
                        $scope.expediente.TotalAplicarAfeccion5_TSJA = $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].TotalAplicarAfeccion5_TSJA;
                        break;
                    case 'TS':
                        $scope.expediente.ImporteTS = ($scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ImporteTS);
                        $scope.expediente.ImporteTS = $scope.expediente.ImporteTS  + " €";
                        $scope.expediente.TotalAplicarAfeccion5_TS = $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].TotalAplicarAfeccion5_TS;
                        break;
                }
                ActualizarTotalACobrar($scope);
            }

            function ActualizarTotalACobrar($scope) {
                var importeExpediente = $scope.CodigoEstadoActual == 'TS' ? Utilidades.numberFormat($scope.expediente.ImporteTS) :
                    $scope.CodigoEstadoActual == 'TSJA' ? Utilidades.numberFormat($scope.expediente.ImporteTSJA) :
                        $scope.CodigoEstadoActual == 'RJP' ? (Utilidades.numberFormat($scope.expediente.ImporteRJP) + (($scope.expediente.TotalAplicarAfeccion5_RJP != '') ? (Utilidades.numberFormat($scope.expediente.TotalAplicarAfeccion5_RJP) * 0.05) : 0)) :
                            $scope.CodigoEstadoActual == 'HAA' ? (Utilidades.numberFormat($scope.expediente.ImporteHAA) + (($scope.expediente.TotalAplicarAfeccion5 != '') ? (Utilidades.numberFormat($scope.expediente.TotalAplicarAfeccion5) * 0.05) : 0)) :
                                $scope.CodigoEstadoActual == 'MA' ? Utilidades.numberFormat($scope.expediente.ImporteMA) :
                                    $scope.CodigoEstadoActual == 'COT' && $scope.expediente.ImporteCOT != undefined ? Utilidades.numberFormat($scope.expediente.ImporteCOT) : 0;

                $scope.AdeducirDP = $scope.estadoHayDP ?
                    (Utilidades.numberFormat($scope.expediente.DepositoPrevio)
                                            + Utilidades.numberFormat($scope.expediente.ImporteDPCapitalizacion)
                        + Utilidades.numberFormat($scope.expediente.ImporteAdicionalDP)
                        + ($scope.valoracionServidumbreSubterranea == "" || $scope.valoracionServidumbreSubterranea == undefined ? 0 : Utilidades.numberFormat($scope.valoracionServidumbreSubterranea))
                        + ($scope.valoracionServidumbreAerea == "" || $scope.valoracionServidumbreAerea == undefined ? 0 : Utilidades.numberFormat($scope.valoracionServidumbreAerea)))
                    : 0;


                $scope.AdeducirIRO = $scope.estadoHayDP == true ? Utilidades.numberFormat($scope.expediente.ImporteIndemnizacionPerjuicios) : 0;
                $scope.AdeducirOT = $scope.estadoHayDP == true ? Utilidades.numberFormat($scope.expediente.ImporteValoracionOT) : 0;
                $scope.AdeducirLC = $scope.expediente.HayLimiteConformidad == true ? ((Utilidades.numberFormat($scope.expediente.ImporteHAA) + (Utilidades.numberFormat($scope.expediente.TotalAplicarAfeccion5) * 0.05)) - $scope.AdeducirDP - $scope.AdeducirIRO) : 0;
                var totalACobrar = 0;
                
                const totalDPIROOT = $scope.AdeducirDP + $scope.AdeducirIRO + $scope.AdeducirOT;
                switch ($scope.CodigoEstadoActual) {
                    case 'MA':
                        totalACobrar = importeExpediente - totalDPIROOT;
                        break;
                    case 'COT':
                        totalACobrar = importeExpediente - totalDPIROOT;
                        break;
                    case 'DP IRO':
                        totalACobrar = totalDPIROOT;
                        break;
                    case 'HAP':
                        totalACobrar = importeExpediente - totalDPIROOT;
                        break;
                    case 'HAA':
                        totalACobrar = importeExpediente - totalDPIROOT;
                        break;
                    case 'RJP':
                        totalACobrar = importeExpediente - totalDPIROOT - $scope.AdeducirLC;
                        break;
                    case 'TSJA':
                        totalACobrar = importeExpediente - totalDPIROOT - $scope.AdeducirLC;
                        break;
                    case 'TS':
                        totalACobrar = importeExpediente - totalDPIROOT - $scope.AdeducirLC;
                        break;
                }
                totalACobrar = parseFloat(totalACobrar.toFixed(2));
                totalACobrar = $filter('currency')(totalACobrar);

                $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].TotalACobrar = totalACobrar;
                if ($scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].IdEstado == $scope.estadoActualInicialObtener()) {
                    $scope.expediente.TotalACobrar = totalACobrar;
                    $scope.ImporteValoracion = getImporteValoracion($scope.expediente);
                }
            };
            $scope.valoracionInicializar = function () {
                $scope.pestanyavisible = 'valoracion';

                if ($scope.cuadroPreciosClave == null) {
                    expedientesService.getCuadroPreciosClave($routeParams.idClave)
                           .success(function (data) {
                               $scope.cuadroPreciosClave = data;
                           });
                    $scope.DatosFincaCargar($scope.pestanyavisible);
                }
                //Mirar si hay valoración para estado actual
                if ($scope.datosValoracionExpediente == null) {
                    $scope.datosValoracionExpediente = [];
                    var datosValoracionExpedienteActual = {
                        IdEstado: $scope.EstadoActual,
                        IdNuevaValoracion: 0,
                        ElementosValoracion: [],
                        DepositoPrevio: '',
                        ImporteAdicionalDP: '',
                        ValoracionAdicionalDP: '',
                        ImporteIndemnizacionPerjuicios: '',
                        OcupacionTemporal: '',
                        ImporteMA: '',
                        ImporteHAP: '',
                        ImporteHAA: '',
                        ImporteRJP: '',
                        ImporteTSJA: '',
                        ImporteTS: '',
                        TotalACobrar: ''
                    }
                    $scope.datosValoracionExpediente.push(datosValoracionExpedienteActual);
                    $scope.OrdinalValoracionActual = 0;
                    $scope.datosValoracionObtener($scope.EstadoActual);
                } else {
                    var ordvaloracion = -1;
                    for (var i = 0; i < $scope.datosValoracionExpediente.length; i++) {
                        if ($scope.datosValoracionExpediente[i].IdEstado == $scope.EstadoActual) {
                            ordvaloracion = i;
                            break;
                        }
                    }
                    $scope.OrdinalValoracionActual = ordvaloracion;
                    if (ordvaloracion < 0) {
                        var datosValoracionExpedienteActual = {
                            IdEstado: $scope.EstadoActual,
                            IdNuevaValoracion: 0,
                            ElementosValoracion: [],
                            DepositoPrevio: '',
                            ImporteAdicionalDP: '',
                            ValoracionAdicionalDP: '',
                            ImporteIndemnizacionPerjuicios: '',
                            OcupacionTemporal: '',
                            ImporteMA: '',
                            ImporteHAP: '',
                            ImporteHAA: '',
                            ImporteRJP: '',
                            ImporteTSJA: '',
                            ImporteTS: '',
                            TotalACobrar: ''
                        }
                        $scope.datosValoracionExpediente.push(datosValoracionExpedienteActual);
                        $scope.OrdinalValoracionActual = $scope.datosValoracionExpediente.length - 1;
                    }
                    $scope.datosValoracionObtener($scope.EstadoActual);
                }
            }

            $scope.precioElementoEstablecer = function (index, elementoValoracion) {
                cuadroPreciosClaveFiltrado = $filter('filter')($scope.cuadroPreciosClave, { Descripcion: elementoValoracion.Descripcion });
                elementoValoracion.Precio = cuadroPreciosClaveFiltrado[0].Precio;
                $scope.importeActualizar(elementoValoracion);
            }
            $scope.importeActualizar = function (elementoValoracion) {
                elementoValoracion.Importe = Utilidades.numberFormat(elementoValoracion.Precio) * Utilidades.numberFormat(elementoValoracion.Unidades);
                elementoValoracion.Importe = parseFloat(elementoValoracion.Importe.toFixed(2));
                elementoValoracion.Importe = $filter('currency')(elementoValoracion.Importe);
                if (hayQueCalcularSumaAfeccion5($scope.CodigoEstadoActual)) {
                    $scope.SumaAfeccion5Calcular();
                }
                $scope.importeTotalValoracionCalcular();
            }
            $scope.totalAplicarAfeccion5Calcular = function () {
                var totalAplicarAfeccion5 = 0;
                var elementoCPC;
                for (var i = 0; i < $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ElementosValoracion.length; i++) {
                    elementoCPC = $filter('filter')($scope.cuadroPreciosClave, { Descripcion: $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ElementosValoracion[i].Descripcion });
                    if (elementoCPC.length > 0) {
                        totalAplicarAfeccion5 += (elementoCPC[0].Afeccion5 == true) ? Utilidades.numberFormat($scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ElementosValoracion[i].Importe) : 0;
                    }
                }
                $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].TotalAplicarAfeccion5 = $filter('currency')(totalAplicarAfeccion5);
                $scope.SumaAfeccion5Calcular();
            }
            function calcularImporteTotal(elementosValoracion) {
                if (!elementosValoracion)
                    return 0;
                
                var importeTotalValoracion = elementosValoracion.reduce(function (total, value) {
                    return total + Utilidades.numberFormat(value.Importe);
                }, 0);
                return parseFloat(importeTotalValoracion.toFixed(2));
            }

            $scope.importeTotalValoracionCalcular = function () {
                var importeTotalValoracion = calcularImporteTotal(getElementosValoracion());
                importeTotalValoracion = $filter('currency')(importeTotalValoracion);

                var importeTotalValoracionOT = calcularImporteTotal(getElementosValoracionOT());
                importeTotalValoracionOT = $filter('currency')(importeTotalValoracionOT);

                var valoracionServidumbreSubterranea = "0";
                var valoracionServidumbreAerea = "0";
                switch ($scope.CodigoEstadoActual) {
                    case "MA":
                        $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ImporteMA = importeTotalValoracion;
                        $scope.expediente.ImporteMA = importeTotalValoracion;
                        break;
                    case "COT":
                        $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].OcupacionTemporal = importeTotalValoracion;
                        $scope.expediente.ImporteCOT = importeTotalValoracion;
                        break;
                    case "DP IRO":
                        $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ImporteIndemnizacionPerjuicios = importeTotalValoracion;
                        $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ImporteValoracionOT = importeTotalValoracionOT;

                        $scope.expediente.ImporteIndemnizacionPerjuicios = importeTotalValoracion;
                        $scope.expediente.ImporteValoracionOT = importeTotalValoracionOT;
                        
                        if ($scope.expediente.Finca != "") {
                            if (!esFincaArrendada($scope.expediente.Finca)) {
                                const valorCatastral = toNumber($scope.expediente.Finca.Finca.ValorCatastral);
                                const superficieTotal = toNumber($scope.expediente.Finca.Finca.SuperficieTotal);
                                const servidumbreSubterranea = toNumber($scope.expediente.ServidumbreSubterranea);
                                const servidumbreAerea = toNumber($scope.expediente.ServidumbreAerea);

                                valoracionServidumbreSubterranea = (valorCatastral * servidumbreSubterranea * 0.6) / superficieTotal;
                                valoracionServidumbreAerea = (valorCatastral * servidumbreAerea * 0.4) / superficieTotal;

                                valoracionServidumbreSubterranea = valoracionServidumbreSubterranea.toFixed(2).replace(".", ",");
                                valoracionServidumbreAerea = valoracionServidumbreAerea.toFixed(2).replace(".", ",");
                            }
                        }
                        break;
                    case "HAP":
                        $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ImporteHAP = importeTotalValoracion;
                        $scope.expediente.ImporteHAP = importeTotalValoracion;
                        break;
                    case "HAA":
                        $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ImporteHAA = importeTotalValoracion;
                        $scope.expediente.ImporteHAA = importeTotalValoracion;
                        //Calcular el total a aplicar afección del 5% 
                        $scope.totalAplicarAfeccion5Calcular();
                        $scope.expediente.TotalAplicarAfeccion5 = $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].TotalAplicarAfeccion5;
                        break;
                    case "RJP":
                        $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ImporteRJP = importeTotalValoracion;
                        $scope.expediente.ImporteRJP = importeTotalValoracion;
                        $scope.SumaAfeccion5Calcular();
                        $scope.expediente.TotalAplicarAfeccion5_RJP = $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].TotalAplicarAfeccion5_RJP;
                        break;
                    case "TSJA":
                        $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ImporteTSJA = importeTotalValoracion;
                        $scope.expediente.ImporteTSJA = importeTotalValoracion;
                        break;
                    case "TS":
                        $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ImporteTS = importeTotalValoracion;
                        $scope.expediente.ImporteTS = importeTotalValoracion;
                        break;
                }

                ActualizarTotalACobrar($scope);
            }

            $scope.nuevoElementoValoracion = function () {
                if ($scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].IdNuevaValoracion == null) {
                    $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].IdNuevaValoracion = 0;
                }
                $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].IdNuevaValoracion--;
                var elementoValoracion = {
                    Id: $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].IdNuevaValoracion,
                    ExpedienteId: $scope.ExpedienteId,
                    Ordinal: $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ElementosValoracion.length + 1,
                    Descripcion: '',
                    Unidades: '',
                    Precio: '',
                    Importe: ''
                }

                $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].IdNuevaValoracion--;
                $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ElementosValoracion.push(elementoValoracion);
            }
            $scope.eliminarElementoValoracion = function (index) {
                var elementos = getElementosValoracion();
                elementos.splice(index, 1);
                renumerarElementosValoracion(elementos);
                $scope.SumaAfeccion5Calcular();
                $scope.importeTotalValoracionCalcular();
            }

            $scope.nuevoElementoValoracionOT = function () {
                if ($scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].IdNuevaValoracion == null) {
                    $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].IdNuevaValoracion = 0;
                }
                $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].IdNuevaValoracion--;
                var elementoValoracion = {
                    Id: $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].IdNuevaValoracion,
                    ExpedienteId: $scope.ExpedienteId,
                    Ordinal: $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ElementosValoracionOT.length + 1,
                    Descripcion: '',
                    Unidades: '',
                    Precio: '',
                    Importe: '',
                    EstadoId: 10
                }
                $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ElementosValoracionOT.push(elementoValoracion);
                renumerarElementosValoracion(getElementosValoracionOT());
            }
            $scope.eliminarElementoValoracionOT = function (index) {
                var elementos = getElementosValoracionOT();
                elementos.splice(index, 1);
                renumerarElementosValoracion(elementos);
                $scope.importeTotalValoracionCalcular();
            }

            //Fin Valoración

            //GIS
            $scope.initGIS = function () {
                try {
                    uiGmapGoogleMapApi.then(function (maps) {
                        $scope.GISiniciado = 1;
                        $scope.map = {
                            center: {
                                latitude: $scope.expediente.CoordenadaXCentroPoligono,
                                longitude: $scope.expediente.CoordenadaYCentroPoligono
                            },
                            zoom: 18,
                            show: true,
                            options: {
                                mapTypeId: google.maps.MapTypeId.SATELLITE
                            }
                        }

                        //google.maps.event.trigger($scope.map, 'resize');                       
                    })
                } catch (e) {
                    alert(e);
                }
            }
            //Apaño para ver GIS
            $("a[data-target='#gis']").on('shown.bs.tab', function () {
                if ($scope.expediente.CoordenadasPoligono == null || $scope.expediente.CoordenadasPoligono == '')
                    alert('No existe GIS para esta parcela');

                if ($scope.GISiniciado == 0) {
                    $scope.initGIS();
                }
            });
            //FIN Apaño para ver GIS

            //fin GIS

            $scope.FechaOcupacionCalcular = function () {
                //if ($scope.expediente.FechaOcupacion == null) {
                if ($scope.expediente.CompareceCitacionAP == true && $scope.expediente.AutorizaAP == true) {
                    //Si comparece y autoriza la fecha de ocupación es la del levantamiento de AP
                    if ($scope.expediente.FechaLevantamientoAP != null) {
                        $scope.expediente.FechaOcupacion = $scope.expediente.FechaLevantamientoAP;
                    }
                } else if ($scope.expediente.AutorizaAP == false || $scope.expediente.CompareceCitacionAP == false) {
                    //Si no autoriza la fecha de ocupación es la del día siguiente al pago del DP
                    if ($scope.expediente.FechaPagoDP != null) {
                        var fechaOcupacionCalculada = new Date($scope.expediente.FechaPagoDP);
                        fechaOcupacionCalculada.setDate(fechaOcupacionCalculada.getDate() + 1);
                        $scope.expediente.FechaOcupacion = fechaOcupacionCalculada;
                    } else {
                        $scope.expediente.FechaOcupacion = null;
                    }
                }

                if ($scope.expediente.CompareceCitacionAP == 0) {
                    $scope.expediente.AutorizaAP = null;
                    $scope.expediente.SinRenunciaAO = null;
                }

                if ($scope.expediente.AutorizaAP == 0) {
                    $scope.expediente.SinRenunciaAO = null;
                }
                //}
            };

            if ($scope.ExpedienteId > 0) {
                expedientesService.getExpediente($scope.ExpedienteId)
                      .success(function (data) {
                          $scope.expediente = data;

                          $scope.ImporteValoracion = getImporteValoracion($scope.expediente);

                          $scope.LimiteConformidad = ($scope.expediente.HayLimiteConformidad == true) ? ((Utilidades.numberFormat($scope.expediente.ImporteHAA) + (Utilidades.numberFormat($scope.expediente.TotalAplicarAfeccion5) * 0.05))) : 0;
                   
                          AnadirFechaAHoras($scope.expediente);
                          
                          if ($scope.expediente.FechaOcupacion == null) $scope.FechaOcupacionCalcular();

                          //Estados
                          //$scope.expediente.Estados = $scope.estados;
                          //$scope.EstadoActual = $scope.estadoActualInicialObtener();
                          //$scope.CodigoEstadoActual = $scope.codigoEstadoActualObtener();
                          //$scope.estadoHayDP = $scope.estadoHayDPObtener();


                          //Estados
                          expedientesService.getEstadosExpediente($scope.ExpedienteId)
                              .success(function (data) {
                                  $scope.estados = data;
                                  $scope.expediente.Estados = $scope.estados;
                                  $scope.EstadoActual = $scope.estadoActualInicialObtener();
                                  $scope.CodigoEstadoActual = $scope.codigoEstadoActualObtener();
                                  $scope.estadoHayDP = $scope.estadoHayDPObtener();
                              })
                              .error(function (response) {
                              });


                          if ($routeParams.copiado == "s")
                              $scope.expediente.Finca = $scope.FincaCopiada;


                          $scope.FechasExpedienteConvertirUTCALocal("expediente");

                          $scope.KMLFincas = {
                              clickable: false,
                              url: $scope.expediente.KMLFincas,
                              preserveViewport: true
                          };

                          $scope.coordenadas = [];
                          $scope.coordenadas.push($scope.expediente.CoordenadasPoligono);

                          $scope.poligonos = [{
                              id: $scope.expediente.Id,
                              path: $scope.expediente.CoordenadasPoligono,
                              stroke: {
                                  color: '#6038FF',
                                  weight: 1.5
                              },
                              visible: true,
                              fill: {
                                  color: '#6038FF',
                                  opacity: 0.5
                              }
                          }];
                      })
                         .error(function (response) {
                         });
            } else {
                $scope.expediente = {};
                $scope.expediente.Finca = {};
                $scope.expediente.Finca.Finca = {};
                $scope.expediente.Finca.TextoFincaEnExpediente = '';
                $scope.expediente.Finca.NumeroFincaEnExpediente = '';
                $scope.expediente.Finca.FincaArrendada = null;
                $scope.expediente.Finca.FincaComplementaria = null;
                $scope.expediente.Finca.NumHojaPlanoClave = null;
                $scope.expediente.PropietariosFinca = [];
            }

            //Carga finca si viene de copiar finca
            if ($routeParams.copiado == "s")
                expedientesService.getFincasPorExpediente($scope.ExpedienteId)
                                  .success(function (data) {
                                      $scope.FincaCopiada = data;
                                  });

            //Carga maestros y varios
            expedientesService.getEstadosExpediente($scope.ExpedienteId)
                    .success(function (data) {
                        $scope.estados = data;
                    })
                .error(function (response) {
                });
            expedientesService.getClasesFinca()
                .success(function (data) {
                    $scope.clasesfinca = data;
                });
            expedientesService.getCultivosAprovechamientos()
                   .success(function (data) {
                       $scope.cultivosaprovechamientos = data;
                   });
            expedientesService.getTerminosMunicipales()
                   .success(function (data) {
                       $scope.terminosmunicipales = data;
                   });
            expedientesService.getRegistrosMunicipales()
                  .success(function (data) {
                      $scope.registrosmunicipales = data;
                  });
            expedientesService.getTerminosMunicipalesClave($routeParams.idClave)
                   .success(function (data) {
                       $scope.terminosmunicipalesclave = data;
                   });
            expedientesService.getClasificacionesUrbanisticas()
                   .success(function (data) {
                       $scope.clasificacionesurbanisticas = data;
                   });
            expedientesService.getTiposExpropiacion()
                   .success(function (data) {
                       $scope.tiposexpropiacion = data;
                   });
            expedientesService.getTiposPago()
                   .success(function (data) {
                       $scope.tipospago = data;
                   });
            expedientesService.getDerechosPropietarios()
                   .success(function (data) {
                       $scope.derechospropietarios = data;
                   });
            expedientesService.getEstadosEspeciales()
                   .success(function (data) {
                       $scope.estadosespeciales = data;
                   });

            $scope.ResumenExpedienteObtener = function () {
                expedientesService.getResumenExpediente($scope.ExpedienteId)
                                   .success(function (data) {
                                       $scope.resumen = data;
                                   });
            }
            $scope.ResumenExpedienteObtener();
            //Carga maestros y varios

            //Planos
            $scope.planoEliminar = function (idPlano, indice) {
                expedientesService.deleteArchivoFinca($scope.ExpedienteId, idPlano, $scope.expediente.Planos[indice].Nombre)
                                      .success(function (data) {
                                          alert('Plano eliminado correctamente');
                                          $scope.expediente.Planos.splice(indice, 1);
                                      })
                     .error(function (response) {
                         $scope.error = expedientesService.obtenerPrimerErrorModelState(response);
                         alert($scope.error);
                     });
            }

            $scope.uploadingMultipleFilesPlanos = false;

            $scope.archivosMultiplesPlanos = [];
            $scope.archivosMultiplesInformes = [];
            $scope.archivosMultiplesImagenes = [];

            $scope.formDataArchivosMultiplesPlanos = new FormData();

            $scope.getFilesPlanos = function (file) {
                angular.forEach(file, function (value, key) {
                    $scope.formDataArchivosMultiplesPlanos.append(key, value);
                    $scope.archivosMultiplesPlanos.push({ FileName: value.name, FileLength: value.size });
                });
                //$scope.numArchivosMultiplesPlanosSeleccionados = $scope.archivosMultiples.length == 0 ? '' : $scope.archivosMultiples.length + ' archivos seleccionados';
                $scope.$apply();
                //$scope.formDataArchivosMultiplesPlanos.append('countFiles', $scope.numArchivosMultiplesPlanosSeleccionados);

            };

            $scope.uploadFilesPlanos = function () {
                $scope.uploadingMultipleFilesPlanos = true;
                expedientesService.postPlanos(($scope.ExpedienteId < 1) ? 0 : $scope.resumen.FincaId, $routeParams.idClave, $scope.ExpedienteId, $scope.formDataArchivosMultiplesPlanos)
                    .then(function (data) {
                        $scope.uploadingMultipleFilesPlanos = false;
                        $scope.formDataArchivosMultiplesPlanos = new FormData();
                        $scope.archivosMultiplesPlanos = [];
                        //$scope.numArchivosMultiplesPlanosSeleccionados = '';
                        $scope.$apply;
                        //Actualizar planos expediente
                        expedientesService.getPlanos($scope.ExpedienteId)
                            .success(function (data) {
                                $scope.expediente.Planos = data;
                            })
                            .error(function (response) {
                                $location.path('/expedientes/' + $routeParams.idClave);
                            });
                        alert("Planos subidos correctamente");
                    }), function (error) {
                        $scope.uploadingMultipleFilesPlanos = false;
                        alert("Error al subir los planos ");
                    }
                    .error(function (error) {
                        $scope.uploadingMultipleFilesPlanos = false;
                        alert("Error al subir los planos ");
                    });
            };
            //Fin Planos


            //Imágenes SILVIA

            $scope.uploadingMultipleFilesImagenes = false;

            $scope.archivosMultiplesImagenes = [];

            $scope.formDataArchivosMultiplesImagenes = new FormData();

            $scope.getFilesImagenes = function (file) {
                angular.forEach(file, function (value, key) {
                    $scope.formDataArchivosMultiplesImagenes.append(key, value);
                    $scope.archivosMultiplesImagenes.push({ FileName: value.name, FileLength: value.size });
                })
                $scope.$apply();
            };
            
            $scope.uploadFilesImagenes = function () {
                $scope.uploadingMultipleFilesImagenes = true;
                expedientesService.postImagenes((($scope.ExpedienteId < 1) ? 0 : $scope.resumen.FincaId), $routeParams.idClave, $scope.ExpedienteId,$scope.formDataArchivosMultiplesImagenes)
                    .then(function (data) {
                        $scope.uploadingMultipleFilesImagenes = false;
                        $scope.formDataArchivosMultiplesImagenes = new FormData();
                        $scope.archivosMultiplesImagenes = [];
                        
                        $scope.$apply;
                        expedientesService.getImagenesExpediente($scope.ExpedienteId)
                            .success(function (data) {
                                $scope.expediente.Imagenes = data;
                            })
                            .error(function (response) {
                                $location.path('/expedientes/' + $routeParams.idClave);
                            });
                        alert("Imagenes subidas correctamente");
                    }), function (error) {
                        $scope.uploadingMultipleFilesImagenes = false;
                        alert("Error al subir las imagenes ");
                    }
                    .error(function (error) {
                        $scope.uploadingMultipleFilesImagenes = false;
                        alert("Error al subir las imagenes ");
                    });
            };

            $scope.imagenEliminar = function (indice) {
                expedientesService.deleteArchivoFinca($scope.ExpedienteId, $scope.expediente.Imagenes[indice].Id, $scope.expediente.Imagenes[indice].Nombre)
                    .success(function (data) {
                        alert('Imagen eliminada correctamente');
                        $scope.expediente.Imagenes.splice(indice, 1);
                    })
                        .error(function (response) {
                            $scope.error = expedientesService.obtenerPrimerErrorModelState(response);
                            alert($scope.error);
                        });
            }

            $scope.albumCrear = function () {
                expedientesService.createAlbumImagenes($scope.resumen.FincaId, $routeParams.idClave, $scope.ExpedienteId)              
                    .success(function (data, status, headers) {
                        var filename = headers('x-filename');
                        var contentType = 'application/msword';

                        var blob = new Blob([data],
                        { type: contentType });

                        if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                            window.navigator.msSaveBlob(blob, filename);
                        else //Resto
                        {
                            var downloadLink = document.createElement("a");

                            var url = window.URL.createObjectURL(blob);
                            downloadLink.setAttribute("target", "_blank");
                            downloadLink.setAttribute("href", url);
                            downloadLink.setAttribute("download", filename);
                            document.body.appendChild(downloadLink);
                            downloadLink.click();
                            document.body.removeChild(downloadLink);
                        }
                    })
                        .error(function (response) {
                            $scope.error = expedientesService.obtenerPrimerErrorModelState(response);
                            alert($scope.error);
                        });
            }

                //Fin Imágenes

                //Documentación
                //Inicio Iker
                $scope.cargarDocumentacion = function () {
                    pestanyavisible = 'documentacion';
                    expedientesService.getListadoDocumentos($scope.ExpedienteId)
                        .success(function (data) {
                            $scope.archivosExpediente = data;
                        })
                        .error(function (response) {
                            $scope.error = expedientesService.obtenerPrimerErrorModelState(response);
                            alert('Error al obtener la documentación.');
                        });
                }

                $scope.esOficio = function (archivo) {
                    return archivo.TipoArchivoExpediente.Tipo === "Oficio";
                };

                $scope.esImpreso = function (archivo) {
                    return archivo.TipoArchivoExpediente.Tipo === "Impreso";
                };

                $scope.esOtro = function (archivo) {
                    return archivo.TipoArchivoExpediente.Tipo === "Otros";
                };

                $scope.archivoExpedienteEliminar = function (idArchivoExpediente) {
                    expedientesService.deleteArchivoExpediente(idArchivoExpediente)
                                          .success(function (data) {
                                              alert('Archivo eliminado correctamente');
                                              $scope.cargarDocumentacion();
                                          })
                         .error(function (response) {
                             $scope.error = expedientesService.obtenerPrimerErrorModelState(response);
                             alert($scope.error);
                         });
                }
                $scope.fechaEspecificaInforme = false;
                $scope.nombreFecha = '';
                $scope.seleccionarDocumentacion = function (doc) {
                    var blnExisteFecha = true;

                    if (doc == 'ComunicacionPago' && $scope.EstadoInformeId == undefined) {
                        alert('Debe seleccionar un estado');
                        return;
                    }

                    if ($scope.EstadoInformeId == undefined)
                        $scope.EstadoInformeId = 0;
                    $scope.FechaEspecificaInforme = Date.now()

                    //Pedir Id Estado solo en caso de que sea Oficio nº 4
                    expedientesService.getDocumento(doc, $scope.ExpedienteId, $routeParams.idClave, $scope.EstadoInformeId, Date.now(), $scope.FechaEspecificaInforme, $scope.fechaEspecificaInforme)
                         .success(function (data, status, headers) {
                             var filename = headers('x-filename');
                             var contentType = 'application/msword';

                             var blob = new Blob([data],
                             { type: contentType });

                             if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                                 window.navigator.msSaveBlob(blob, filename);
                             else //Resto
                             {
                                 var downloadLink = document.createElement("a");

                                 var url = window.URL.createObjectURL(blob);
                                 downloadLink.setAttribute("target", "_blank");
                                 downloadLink.setAttribute("href", url);
                                 downloadLink.setAttribute("download", filename);
                                 document.body.appendChild(downloadLink);
                                 downloadLink.click();
                                 document.body.removeChild(downloadLink);
                             }

                             $('#myModalSeleccionDocumentacion').modal('hide');
                             $scope.limpiarControlesSeleccionInforme();
                             $scope.cargarDocumentacion();
                         })
                         .error(function (response) {
                             alert('Error al generar el documento.');
                             $('#myModalSeleccionDocumentacion').modal('hide');
                             $scope.limpiarControlesSeleccionInforme();
                             //$scope.error = expedientesService.obtenerPrimerErrorModelState(response);
                         });
                }

                //Inicio Iker
                $scope.generarDocumentacionOtros = function (doc) {
                    //if ($scope.FechaInforme == '' || $scope.FechaInforme == undefined) {
                    //    alert('Debe seleccionar una fecha para el informe.');
                    //    return;
                    //}

                    if (doc == 'SolicitudTransferencia' && $scope.EstadoInformeId == undefined) {
                        alert('Debe seleccionar un estado');
                        return;
                    }
                    if ($scope.EstadoInformeId == undefined)
                        $scope.EstadoInformeId = 0;

                    expedientesService.getDocumentos(doc, $scope.ExpedienteId, $routeParams.idClave, $scope.EstadoInformeId, Date.now(), Date.now(), "")
                         .success(function (data) {
                             $scope.documentoObtenido = data;
                             var filename = doc + '.docx';
                             var contentType = 'application/msword';

                             var blob = new Blob([data],
                             { type: contentType });

                             if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                                 window.navigator.msSaveBlob(blob, filename);
                             else //Resto
                             {
                                 var downloadLink = document.createElement("a");
                                 downloadLink.setAttribute("target", "_blank");
                                 downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
                                 downloadLink.setAttribute('download', filename);
                                 document.body.appendChild(downloadLink);
                                 downloadLink.click();
                                 document.body.removeChild(downloadLink);
                             }

                             $('#myModalSeleccionDocumentacion').modal('hide');
                             $scope.limpiarControlesSeleccionInforme();
                         })
                          .error(function (response) {
                              alert('Error al generar los documentos.');
                              $('#myModalSeleccionExpedientes').modal('hide');
                              $scope.limpiarControlesSeleccionInforme();
                          });

                    $scope.FechaHoraConvocatoria = '';
                    //Fin Iker
                }

                $scope.descargarDocumento = function (idDocumento, nombre, pertenencia) {
                    expedientesService.getDocumentoAlmacenado(idDocumento, pertenencia)
                         .success(function (data, headers) {
                             var filename = nombre;
                             //1 Planos Finca
                             //2 Planos Clave
                             var contentType = 'undefined';//(pertenencia == 1 || pertenencia==2) ? 'undefined' : 'application/msword';

                             var blob = new Blob([data],
                             { type: contentType });
                             if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                                 window.navigator.msSaveBlob(blob, filename);
                             else //Resto
                             {
                                 var downloadLink = document.createElement("a");
                                 var url = window.URL.createObjectURL(blob);
                                 downloadLink.setAttribute("target", "_blank");
                                 downloadLink.setAttribute("href", url);
                                 downloadLink.setAttribute("download", filename);
                                 document.body.appendChild(downloadLink);
                                 downloadLink.click();
                                 document.body.removeChild(downloadLink);
                             }
                         })
                         .error(function (response) {
                             $scope.error = expedientesService.obtenerPrimerErrorModelState(response);
                             alert('Error al obtener el documento.');
                         });
                }

                //Subir informes escaneado
                $scope.uploadingMultipleFilesInformes = false;
                //    $scope.archivosMultiples = [];
                $scope.archivosMultiplesInformes = [];
                $scope.formDataArchivosMultiplesInformes = new FormData();
                $scope.getFilesInformes = function (file, tipoDocumento) {
                    $scope.tipoDocumento = tipoDocumento;
                    file.TipoDocumento = tipoDocumento;
                    angular.forEach(file, function (value, key) {
                        //$scope.formDataArchivosMultiplesInformes.append(key, value);
                        key = key + tipoDocumento;
                        $scope.formDataArchivosMultiplesInformes.append(key, value);
                        $scope.archivosMultiplesInformes.push({ FileName: value.name, FileLength: value.size, FileType: tipoDocumento });
                    });
                    $scope.$apply();
                    $('#myModalEscanearDocumentacion').modal('hide');
                };

                $scope.uploadFilesInformes = function () {
                    $scope.uploadingMultipleFilesInformes = true;
                    expedientesService.postDocumentos($scope.tipoDocumento, $routeParams.idClave, $scope.ExpedienteId, $scope.formDataArchivosMultiplesInformes)              
                        .then(function (data) {
                            $scope.uploadingMultipleFilesInformes = false;
                            $scope.formDataArchivosMultiplesInformes = new FormData();
                            //    $scope.archivosMultiples = [];
                            $scope.archivosMultiplesInformes = [];
                            $scope.$apply;
                            //Actualizar informes
                            $scope.cargarDocumentacion();
                            alert("Archivos subidos correctamente");

                        }), function (error) {
                            alert("Error al subir los archivos ");
                            $scope.uploadingMultipleFilesInformes = false;
                        }
                        .error(function (error) {
                            alert("Error al subir los archivos ");
                            $scope.uploadingMultipleFilesInformes = false;
                        });
                };

                $scope.limpiarControlesSeleccionInforme = function () {
                    //$scope.FechaEspecificaInforme = null;
                    //$scope.HoraEspecificaInforme = null;

                    $scope.EstadoInformeId = null;
                }
                //Fin Iker
                //Fin Documentación

                $scope.volver = function () {
                    if ($routeParams.tit == null) {
                        $location.url('/expedientes/' + $routeParams.idClave + '?gisC=' + $routeParams.gisC + '&gisE=' + $routeParams.gisE);
                    } else if ($routeParams.tit == 0) {
                        $location.url('/claves' + '?gisC=' + $routeParams.gisC);
                    } else {
                        $location.path('/expedientestitular/' + $routeParams.tit);
                    }
                }

                $scope.guardar = function (mostrarAlerta) {
                    $scope.FechasExpedienteAnadir1200();
                    switch ($scope.pestanyavisible) {
                        case 'finca':
                            guardarDatosFinca(mostrarAlerta);
                            break;
                        case 'propietarios':
                            /*for (var i = 0; i < $scope.expediente.PropietariosFinca.length; i++) {
                                $scope.expediente.PropietariosFinca[i].Porcentaje = ($scope.expediente.PropietariosFinca[i].Porcentaje != null) ? Utilidades.numberFormat($scope.expediente.PropietariosFinca[i].Porcentaje) : null;
    
                            }*/
                            function hayPropietarioyDerechoDuplicados(propietariosFinca) {
                                for (var i = 0; i < propietariosFinca.length; ++i){
                                    var prop1 = propietariosFinca[i];
                                    for (var j = i + 1; j < propietariosFinca.length; ++j){
                                        var prop2 = propietariosFinca[j];
                                        if (prop1.Id == prop2.Id && prop1.DerechoPropietarioId == prop2.DerechoPropietarioId) {
                                            return true;
                                        }
                                    }
                                }
                                return false;
                            }
                            function hayPropietariosSinDerecho(propietariosFinca) {
                            
                                var indiceSinDerecho = propietariosFinca.findIndex(function (prop) {
                                    return prop.DerechoPropietarioId == null; 
                                });
                                return indiceSinDerecho != -1;
                            }
                            if (hayPropietariosSinDerecho($scope.expediente.PropietariosFinca)) {
                                alert("Todos los titulares deben tener un tipo de derecho definido. PROPIETARIOS NO GUARDADOS");
                                break;
                            }
                            if (hayPropietarioyDerechoDuplicados($scope.expediente.PropietariosFinca)) {
                                alert("Hay algún titular que aparece más de una vez con el mismo tipo de derecho. PROPIETARIOS NO GUARDADOS");
                                break;
                            }
                            if ($scope.ExpedienteId > 0) {
                                expedientesService.putDatosPropietariosFinca($scope.expediente.PropietariosFinca, $scope.ExpedienteId)
                                    .success(function (data) {
                                        alert('Datos guardados correctamente');
                                    })
                                .error(function (response) {
                                    $scope.error = expedientesService.obtenerPrimerErrorModelState(response);
                                    alert($scope.error);
                                });
                            } else {
                                expedientesService.postDatosPropietariosFinca($scope.expediente.PropietariosFinca, $routeParams.idClave, $scope.ExpedienteId)
                                         .success(function (data) {
                                             alert('Datos guardados correctamente');
                                             $scope.ExpedienteId = data;
                                             expedientesService.getExpediente($scope.ExpedienteId)
                                                     .success(function (data) {
                                                         $scope.expediente = data;

                                                         $scope.FechasExpedienteConvertirUTCALocal("expediente");

                                                AnadirFechaAHoras($scope.expediente);
                                                     })
                                                 .error(function (response) {
                                                     $location.path('/expedientes/' + $routeParams.idClave);
                                                 });
                                         })
                                     .error(function (response) {
                                         $scope.error = expedientesService.obtenerPrimerErrorModelState(response);
                                         alert($scope.error);
                                     });
                            }
                            break;
                        case 'expediente':
                            var expediente = {
                                Id: $scope.expediente.Id,
                                SuperficieAExpropiarPrevia: Utilidades.numberFormat($scope.expediente.SuperficieAExpropiarPrevia),
                                SuperficieAExpropiarDefinitiva: Utilidades.numberFormat($scope.expediente.SuperficieAExpropiarDefinitiva),
                                //SuperficieAExpropiarPrevia: $scope.expediente.SuperficieAExpropiarPrevia,
                                //SuperficieAExpropiarDefinitiva: $scope.expediente.SuperficieAExpropiarDefinitiva,
                                TipoExpropiacionId: $scope.expediente.TipoExpropiacionId,
                                OcupacionTemporal: Utilidades.numberFormat($scope.expediente.OcupacionTemporal),
                                ServidumbreAerea: Utilidades.numberFormat($scope.expediente.ServidumbreAerea),
                                ServidumbreSubterranea: Utilidades.numberFormat($scope.expediente.ServidumbreSubterranea),
                                FechaRegistroSalidaLevantamientoAP: $scope.expediente.FechaRegistroSalidaLevantamientoAP,
                                FechaRecepcionLevantamientoAP: $scope.expediente.FechaRecepcionLevantamientoAP,
                                FechaLevantamientoAP: $scope.expediente.FechaLevantamientoAP,
                                HoraLevantamientoAP: $scope.expediente.HoraLevantamientoAP,
                           DescripcionBienesAfectados: $scope.expediente.DescripcionBienesAfectados,
                                ManifestacionesReversoAP: $scope.expediente.ManifestacionesReversoAP,
                                ObservacionesHAYReversoMA: $scope.expediente.ObservacionesHAYReversoMA,
                                CompareceCitacionAP: ($scope.expediente.CompareceCitacionAP == 1) ? true : (($scope.expediente.CompareceCitacionAP == 0) ? false : null),
                                AutorizaAP: ($scope.expediente.AutorizaAP == 1) ? true : (($scope.expediente.AutorizaAP == 0) ? false : null),
                                SinRenunciaAO: ($scope.expediente.SinRenunciaAO == 1) ? true : (($scope.expediente.SinRenunciaAO == 0) ? false : null),
                                FechaOcupacion: $scope.expediente.FechaOcupacion,
                                AsignacionExpedienteDP: $scope.expediente.AsignacionExpedienteDP,
                                TipoPagoDP: { Id: 0, Descripcion: ($scope.expediente.TipoPagoDP == null) ? null : $scope.expediente.TipoPagoDP.Descripcion },
                                FechaCitacionesPagos: $scope.expediente.FechaCitacionesPagos,
                                HoraCitacionesPagos: $scope.expediente.HoraCitacionesPagos,
                                FechaRegistroSalidaCitacionesPagos: $scope.expediente.FechaRegistroSalidaCitacionesPagos,
                                FechaRecepcionCitacionesPagos: $scope.expediente.FechaRecepcionCitacionesPagos,
                           FechaCitacionAO: $scope.expediente.FechaCitacionAO,
                           HoraCitacionAO: $scope.expediente.HoraCitacionAO,
                                AsignacionExpedienteMA: $scope.expediente.AsignacionExpedienteMA, //$scope.expediente.AsignacionExpedienteMAPorPagosExpediente,
                                TipoPagoMA: { Id: 0, Descripcion: ($scope.expediente.TipoPagoMA == null) ? null : $scope.expediente.TipoPagoMA.Descripcion },
                                FechaActaMA: $scope.expediente.FechaActaMA,
                                HoraActaMA: $scope.expediente.HoraActaMA,
                                //FechaRemisionActasMA: $scope.expediente.FechaRemisionActasMA,
                                FechaRegistroSalidaRemisionActasMA: $scope.expediente.FechaRegistroSalidaRemisionActasMA,
                                FechaRecepcionRemisionActasMA: $scope.expediente.FechaRecepcionRemisionActasMA,
                                HayFirmaMA: ($scope.expediente.HayFirmaMA == 1) ? true : (($scope.expediente.HayFirmaMA == 0) ? false : null),
                                FechaRegistroSalidaComunicacionPago: $scope.expediente.FechaRegistroSalidaComunicacionPago,
                                AsignacionExpedienteCOT: $scope.expediente.AsignacionExpedienteCOT,
                                TipoPagoCOT: { Id: 0, Descripcion: ($scope.expediente.TipoPagoCOT == null) ? null : $scope.expediente.TipoPagoCOT.Descripcion },
                                EsCOT: $scope.expediente.EsCOT,// ($scope.expediente.EsCOT == 1) ? true : (($scope.expediente.EsCOT == 0) ? false : null),
                                FechaRegistroSalidaOT: $scope.expediente.FechaRegistroSalidaOT,
                                FechaRecepcionOT: $scope.expediente.FechaRecepcionOT,
                                FechaRegistroSalidaNotificacionOcupacion: $scope.expediente.FechaRegistroSalidaNotificacionOcupacion,
                                FechaRecepcionNotificacionOcupacion: $scope.expediente.FechaRecepcionNotificacionOcupacion,
                                HayValoracionPropietario: ($scope.expediente.HayValoracionPropietario == 1) ? true : (($scope.expediente.HayValoracionPropietario == 0) ? false : null),
                                FechaRegistroSalidaRequerimientoValoracion: $scope.expediente.FechaRegistroSalidaRequerimientoValoracion,
                                FechaRecepcionRequerimientoValoracion: $scope.expediente.FechaRecepcionRequerimientoValoracion,
                                AsignacionExpedienteHAA: $scope.expediente.AsignacionExpedienteHAA,
                                TipoPagoHAA: { Id: 0, Descripcion: ($scope.expediente.TipoPagoHAA == null) ? null : $scope.expediente.TipoPagoHAA.Descripcion },
                                FechaHAA: $scope.expediente.FechaHAA,
                                HayLimiteConformidad: ($scope.expediente.HayLimiteConformidad == 1) ? true : (($scope.expediente.HayLimiteConformidad == 0) ? false : null),
                                FechaRegistroSalidaRemisionHAA: $scope.expediente.FechaRegistroSalidaRemisionHAA,
                                FechaRecepcionRemisionHAA: $scope.expediente.FechaRecepcionRemisionHAA,
                                HayFirmaHAA: ($scope.expediente.HayFirmaHAA == 1) ? true : (($scope.expediente.HayFirmaHAA == 0) ? false : null),
                                AsignacionExpedienteJP: $scope.expediente.AsignacionExpedienteJP,
                                FechaPiezaSeparadaJustiprecio: $scope.expediente.FechaPiezaSeparadaJustiprecio,
                                HayResolucionJP: ($scope.expediente.HayResolucionJP == 1) ? true : (($scope.expediente.HayResolucionJP == 0) ? false : null),
                                AsignacionExpedienteRJP: $scope.expediente.AsignacionExpedienteRJP,
                                TipoPagoRJP: { Id: 0, Descripcion: ($scope.expediente.TipoPagoRJP == null) ? null : $scope.expediente.TipoPagoRJP.Descripcion },
                                FechaResolucionJP: $scope.expediente.FechaResolucionJP,
                                ResolucionJPAceptada: ($scope.expediente.ResolucionJPAceptada == 1) ? true : (($scope.expediente.ResolucionJPAceptada == 0) ? false : null),
                                AsignacionExpedienteTSJA: $scope.expediente.AsignacionExpedienteTSJA,
                                TipoPagoTSJA: { Id: 0, Descripcion: ($scope.expediente.TipoPagoTSJA == null) ? null : $scope.expediente.TipoPagoTSJA.Descripcion },
                                FechaRemisionTSJA: $scope.expediente.FechaRemisionTSJA,
                                ResolucionTSJAAceptada: ($scope.expediente.ResolucionTSJAAceptada == 1) ? true : (($scope.expediente.ResolucionTSJAAceptada == 0) ? false : null),
                                AsignacionExpedienteTS: $scope.expediente.AsignacionExpedienteTS,
                                TipoPagoTS: { Id: 0, Descripcion: ($scope.expediente.TipoPagoTS == null) ? null : $scope.expediente.TipoPagoTS.Descripcion },
                                FechaRemisionTS: $scope.expediente.FechaRemisionTS,
                                EstadoId: $scope.expediente.EstadoId,
                                Notas: $scope.expediente.Notas,
                           Cultivo: $scope.expediente.Cultivo,
                            }
                            if ($scope.ExpedienteId > 0) {
                                expedientesService.putExpediente(expediente, $scope.ExpedienteId)
                                     .success(function (data) {
                                         alert('Datos guardados correctamente');
                                         expedientesService.getEstadosExpediente($scope.ExpedienteId)
                                                 .success(function (data) {
                                                     $scope.expediente.Estados = data;
                                                     $scope.EstadoActual = $scope.estadoActualInicialObtener();
                                                     $scope.CodigoEstadoActual = $scope.codigoEstadoActualObtener();
                                                     $scope.estadoHayDP = $scope.estadoHayDPObtener();
                                                 })
                                             .error(function (response) {
                                                 $location.path('/expedientes/' + $routeParams.idClave);
                                             });
                                     })
                                 .error(function (response) {
                                     $scope.error = expedientesService.obtenerPrimerErrorModelState(response);
                                     alert($scope.error);
                                 });
                            } else {
                                expedientesService.postExpediente(expediente, $routeParams.idClave, $scope.ExpedienteId)
                                         .success(function (data) {
                                             alert('Datos guardados correctamente');
                                             $scope.ExpedienteId = data;
                                             expedientesService.getExpediente($scope.ExpedienteId)
                                                     .success(function (data) {
                                                         $scope.expediente = data;

                                                         $scope.FechasExpedienteConvertirUTCALocal("expediente");

                                                AnadirFechaAHoras($scope.expediente);

                                                         $scope.EstadoActual = $scope.estadoActualInicialObtener();
                                                         $scope.CodigoEstadoActual = $scope.codigoEstadoActualObtener();
                                                         $scope.estadoHayDP = $scope.estadoHayDPObtener();
                                                     })
                                                 .error(function (response) {
                                                     $location.path('/expedientes/' + $routeParams.idClave);
                                                 });
                                         })
                                     .error(function (response) {
                                         $scope.error = expedientesService.obtenerPrimerErrorModelState(response);
                                         alert($scope.error);
                                     });
                            }
                            break;
                        case 'valoracion':
                            if ($scope.ExpedienteId > 0) {
                                expedientesService.putValoracion($scope.datosValoracionExpediente[$scope.OrdinalValoracionActual], $scope.ExpedienteId)
                                     .success(function (data) {
                                         if (mostrarAlerta) {
                                             alert('Datos guardados correctamente');
                                         }
                                         $scope.valoracionInicializar();
                                     })
                                 .error(function (response) {
                                     $scope.error = expedientesService.obtenerPrimerErrorModelState(response);
                                     if (mostrarAlerta)
                                         alert($scope.error);
                                 });
                            } else {
                                expedientesService.postValoracion($scope.datosValoracionExpediente[$scope.OrdinalValoracionActual], $routeParams.idClave, $scope.ExpedienteId)
                                         .success(function (data) {
                                             alert('Datos guardados correctamente');
                                             $scope.ExpedienteId = data;
                                             expedientesService.getExpediente($scope.ExpedienteId)
                                                     .success(function (data) {
                                                         $scope.expediente = data;

                                                         $scope.FechasExpedienteConvertirUTCALocal("expediente");

                                                AnadirFechaAHoras($scope.expediente);

                                                         expedientesService.getValoracion($scope.ExpedienteId)
                                                                .success(function (data) {
                                                                    $scope.elementosValoracion = data;
                                                                });
                                                     })
                                                 .error(function (response) {
                                                     $location.path('/expedientes/' + $routeParams.idClave);
                                                 });
                                         })
                                     .error(function (response) {
                                         $scope.error = expedientesService.obtenerPrimerErrorModelState(response);
                                         if (mostrarAlerta)
                                             alert($scope.error);
                                     });
                            }
                            break;
                        default:
                            break;
                    }
                }

                function guardarDatosFinca(mostrarAlerta) {
                    $scope.expediente.Finca.Finca.CoordenadaX = ($scope.expediente.Finca.Finca.CoordenadaX != null) ? $scope.expediente.Finca.Finca.CoordenadaX.toString().replace(',', '.') : 0;
                    $scope.expediente.Finca.Finca.CoordenadaY = ($scope.expediente.Finca.Finca.CoordenadaY != null) ? $scope.expediente.Finca.Finca.CoordenadaY.toString().replace(',', '.') : 0;
                    if ($scope.ExpedienteId > 0) {
                        expedientesService.putDatosFinca($scope.expediente.Finca, $scope.ExpedienteId)
                            .success(function (data) {
                                if (mostrarAlerta) {
                                    alert('Datos guardados correctamente');
                                }
                                $scope.ResumenExpedienteObtener();
                            })
                            .error(function (response) {
                                $scope.error = expedientesService.obtenerPrimerErrorModelState(response);
                                alert($scope.error);
                            });
                    } else {
                        if ($scope.expediente.Finca.Finca.MunicipioId > 0) {
                            expedientesService.postDatosFinca($scope.expediente.Finca, $routeParams.idClave, $scope.ExpedienteId)
                                .success(function (data) {
                                    if (mostrarAlerta) {
                                        alert('Datos guardados correctamente');
                                    }
                                    $scope.ExpedienteId = data;
                                    expedientesService.getExpediente($scope.ExpedienteId)
                                        .success(function (data) {
                                            $scope.expediente = data;

                                            $scope.FechasExpedienteConvertirUTCALocal("expediente");

                                        AnadirFechaAHoras($scope.expediente);

                                            $scope.ResumenExpedienteObtener();
                                        })
                                        .error(function (response) {
                                            $location.path('/expedientes/' + $routeParams.idClave);
                                        });
                                })
                                .error(function (response) {
                                    $scope.error = expedientesService.obtenerPrimerErrorModelState(response);
                                    alert($scope.error);
                                });
                        } else {
                            alert('Falta el término municipal al que pertenece la finca');
                        }
                    }
                }

                $scope.FechasExpedienteConvertirUTCALocal = function (moduloCarga) {
                    if (moduloCarga == "expediente") {
                        $scope.expediente.FechaRegistroSalidaLevantamientoAP = $scope.FechaConvertirUTCALocal($scope.expediente.FechaRegistroSalidaLevantamientoAP);
                        $scope.expediente.FechaRecepcionLevantamientoAP = $scope.FechaConvertirUTCALocal($scope.expediente.FechaRecepcionLevantamientoAP);
                        $scope.expediente.FechaLevantamientoAP = $scope.FechaConvertirUTCALocal($scope.expediente.FechaLevantamientoAP);
                        $scope.expediente.FechaOcupacion = $scope.FechaConvertirUTCALocal($scope.expediente.FechaOcupacion);
                        $scope.expediente.FechaCitacionesPagos = $scope.FechaConvertirUTCALocal($scope.expediente.FechaCitacionesPagos);
                        $scope.expediente.FechaRegistroSalidaCitacionesPagos = $scope.FechaConvertirUTCALocal($scope.expediente.FechaRegistroSalidaCitacionesPagos);
                   $scope.expediente.FechaCitacionAO = $scope.FechaConvertirUTCALocal($scope.expediente.FechaCitacionAO);
                        $scope.expediente.FechaRecepcionCitacionesPagos = $scope.FechaConvertirUTCALocal($scope.expediente.FechaRecepcionCitacionesPagos);
                        $scope.expediente.FechaActaMA = $scope.FechaConvertirUTCALocal($scope.expediente.FechaActaMA);
                        $scope.expediente.FechaRegistroSalidaRemisionActasMA = $scope.FechaConvertirUTCALocal($scope.expediente.FechaRegistroSalidaRemisionActasMA);
                        $scope.expediente.FechaRecepcionRemisionActasMA = $scope.FechaConvertirUTCALocal($scope.expediente.FechaRecepcionRemisionActasMA);
                        $scope.expediente.FechaRegistroSalidaComunicacionPago = $scope.FechaConvertirUTCALocal($scope.expediente.FechaRegistroSalidaComunicacionPago);
                        $scope.expediente.FechaRegistroSalidaOT = $scope.FechaConvertirUTCALocal($scope.expediente.FechaRegistroSalidaOT);
                        $scope.expediente.FechaRecepcionOT = $scope.FechaConvertirUTCALocal($scope.expediente.FechaRecepcionOT);
                        $scope.expediente.FechaRegistroSalidaNotificacionOcupacion = $scope.FechaConvertirUTCALocal($scope.expediente.FechaRegistroSalidaNotificacionOcupacion);
                        $scope.expediente.FechaRecepcionNotificacionOcupacion = $scope.FechaConvertirUTCALocal($scope.expediente.FechaRecepcionNotificacionOcupacion);
                        $scope.expediente.FechaRegistroSalidaRequerimientoValoracion = $scope.FechaConvertirUTCALocal($scope.expediente.FechaRegistroSalidaRequerimientoValoracion);
                        $scope.expediente.FechaRecepcionRequerimientoValoracion = $scope.FechaConvertirUTCALocal($scope.expediente.FechaRecepcionRequerimientoValoracion);
                        $scope.expediente.FechaHAA = $scope.FechaConvertirUTCALocal($scope.expediente.FechaHAA);
                        $scope.expediente.FechaRegistroSalidaRemisionHAA = $scope.FechaConvertirUTCALocal($scope.expediente.FechaRegistroSalidaRemisionHAA);
                        $scope.expediente.FechaRecepcionRemisionHAA = $scope.FechaConvertirUTCALocal($scope.expediente.FechaRecepcionRemisionHAA);
                        $scope.expediente.FechaPiezaSeparadaJustiprecio = $scope.FechaConvertirUTCALocal($scope.expediente.FechaPiezaSeparadaJustiprecio);
                        $scope.expediente.FechaResolucionJP = $scope.FechaConvertirUTCALocal($scope.expediente.FechaResolucionJP);
                        $scope.expediente.FechaRemisionTSJA = $scope.FechaConvertirUTCALocal($scope.expediente.FechaRemisionTSJA);
                        $scope.expediente.FechaRemisionTS = $scope.FechaConvertirUTCALocal($scope.expediente.FechaRemisionTS);
                    }

                    if (moduloCarga == "finca") {
                        if ($scope.expediente.Finca != "") {
                            $scope.expediente.Finca.Finca.FechaAprovacionPlaneamientoUrbanistico = $scope.FechaConvertirUTCALocal($scope.expediente.Finca.Finca.FechaAprovacionPlaneamientoUrbanistico);

                            if ($scope.expediente.Finca.Registros != null) {
                                angular.forEach($scope.expediente.Finca.Registros, function (registro) {
                                    registro.Fecha = $scope.FechaConvertirUTCALocal(registro.Fecha);
                                })
                            }
                        }
                    }
                    if (moduloCarga == "propietarios") {
                        $scope.expediente.PropietariosFinca.forEach(function (prop) {
                            prop.FechaNacimiento = $scope.FechaConvertirUTCALocal(prop.FechaNacimiento);
                        });
                    }
                }

                $scope.FechaConvertirUTCALocal = function (fecha) {
                    if (fecha != null)
                        return new Date(fecha + "Z");
                }

                $scope.FechasExpedienteAnadir1200 = function () {
                    $scope.expediente.FechaRegistroSalidaLevantamientoAP = $scope.FechaAnadir1200($scope.expediente.FechaRegistroSalidaLevantamientoAP);
                    $scope.expediente.FechaRecepcionLevantamientoAP = $scope.FechaAnadir1200($scope.expediente.FechaRecepcionLevantamientoAP);
                    $scope.expediente.FechaLevantamientoAP = $scope.FechaAnadir1200($scope.expediente.FechaLevantamientoAP);
                    $scope.expediente.FechaOcupacion = $scope.FechaAnadir1200($scope.expediente.FechaOcupacion);
                    $scope.expediente.FechaCitacionesPagos = $scope.FechaAnadir1200($scope.expediente.FechaCitacionesPagos);
                    $scope.expediente.FechaRegistroSalidaCitacionesPagos = $scope.FechaAnadir1200($scope.expediente.FechaRegistroSalidaCitacionesPagos);
                $scope.expediente.FechaCitacionAO = $scope.FechaAnadir1200($scope.expediente.FechaCitacionAO);
                    $scope.expediente.FechaRecepcionCitacionesPagos = $scope.FechaAnadir1200($scope.expediente.FechaRecepcionCitacionesPagos);
                    $scope.expediente.FechaActaMA = $scope.FechaAnadir1200($scope.expediente.FechaActaMA);
                    $scope.expediente.FechaRegistroSalidaRemisionActasMA = $scope.FechaAnadir1200($scope.expediente.FechaRegistroSalidaRemisionActasMA);
                    $scope.expediente.FechaRecepcionRemisionActasMA = $scope.FechaAnadir1200($scope.expediente.FechaRecepcionRemisionActasMA);
                    $scope.expediente.FechaRegistroSalidaComunicacionPago = $scope.FechaAnadir1200($scope.expediente.FechaRegistroSalidaComunicacionPago);
                    $scope.expediente.FechaRegistroSalidaOT = $scope.FechaAnadir1200($scope.expediente.FechaRegistroSalidaOT);
                    $scope.expediente.FechaRecepcionOT = $scope.FechaAnadir1200($scope.expediente.FechaRecepcionOT);
                    $scope.expediente.FechaRegistroSalidaNotificacionOcupacion = $scope.FechaAnadir1200($scope.expediente.FechaRegistroSalidaNotificacionOcupacion);
                    $scope.expediente.FechaRecepcionNotificacionOcupacion = $scope.FechaAnadir1200($scope.expediente.FechaRecepcionNotificacionOcupacion);
                    $scope.expediente.FechaRegistroSalidaRequerimientoValoracion = $scope.FechaAnadir1200($scope.expediente.FechaRegistroSalidaRequerimientoValoracion);
                    $scope.expediente.FechaRecepcionRequerimientoValoracion = $scope.FechaAnadir1200($scope.expediente.FechaRecepcionRequerimientoValoracion);
                    $scope.expediente.FechaHAA = $scope.FechaAnadir1200($scope.expediente.FechaHAA);
                    $scope.expediente.FechaRegistroSalidaRemisionHAA = $scope.FechaAnadir1200($scope.expediente.FechaRegistroSalidaRemisionHAA);
                    $scope.expediente.FechaRecepcionRemisionHAA = $scope.FechaAnadir1200($scope.expediente.FechaRecepcionRemisionHAA);
                    $scope.expediente.FechaPiezaSeparadaJustiprecio = $scope.FechaAnadir1200($scope.expediente.FechaPiezaSeparadaJustiprecio);
                    $scope.expediente.FechaResolucionJP = $scope.FechaAnadir1200($scope.expediente.FechaResolucionJP);
                    $scope.expediente.FechaRemisionTSJA = $scope.FechaAnadir1200($scope.expediente.FechaRemisionTSJA);
                    $scope.expediente.FechaRemisionTS = $scope.FechaAnadir1200($scope.expediente.FechaRemisionTS);

                    if ($scope.expediente.Finca != "") {
                        $scope.expediente.Finca.Finca.FechaAprovacionPlaneamientoUrbanistico = $scope.FechaAnadir1200($scope.expediente.Finca.Finca.FechaAprovacionPlaneamientoUrbanistico);

                        if ($scope.expediente.Finca.Registros != null) {
                            angular.forEach($scope.expediente.Finca.Registros, function (registro) {
                                registro.Fecha = $scope.FechaAnadir1200(registro.Fecha);
                            })
                        }
                    }
                    if ($scope.expediente.PropietariosFinca != "") {
                        $scope.expediente.PropietariosFinca.forEach(function (prop) {
                            prop.FechaNacimiento = $scope.FechaAnadir1200(prop.FechaNacimiento);
                        });
                    }
                }

                //$scope.FechaAnadir1200 = function (fecha) {
                //    if (fecha != null)
                //        return new Date(fecha.setHours(12, 0));
                //}

            $scope.FechaAnadir1200 = function (fecha) {
                //if (fecha != null && fecha != "" && fecha != undefined && typeof (fecha) != "string") {
                if (fecha != null && fecha != "" && fecha != undefined) {
                    if (typeof (fecha) == "string") {
                        var aux = new Date(fecha);
                        var fecha1 = aux.setHours(12, 0, 0);
                        return aux;
                    } else {
                        var aux = fecha;
                        var fecha1 = aux.setHours(12, 0, 0);
                        return aux;
                    }
                }
                return null;
            }

            function AnadirFechaAHoras(expediente){
                expediente.HoraLevantamientoAP = aniadirFechaAHora(expediente.HoraLevantamientoAP);
                expediente.HoraCitacionesPagos = aniadirFechaAHora(expediente.HoraCitacionesPagos);
                expediente.HoraActaMA = aniadirFechaAHora(expediente.HoraActaMA);
                expediente.HoraCitacionAO = aniadirFechaAHora(expediente.HoraCitacionAO);

            }
                //Inicio pestanyas cargar
                //Fincas
                $scope.DatosFincaCargar = function (pestanya) {
                    $scope.pestanyavisible = pestanya;
                    if ($scope.expediente.Finca == "") {
                        expedientesService.getFincasPorExpediente($scope.ExpedienteId)
                             .success(function (data) {
                                 $scope.expediente.Finca = data;
                                 $scope.FechasExpedienteConvertirUTCALocal("finca");

                                 var valoracionServidumbreSubterranea = 0;
                                 var valoracionServidumbreAerea = 0;
                                 if (!esFincaArrendada($scope.expediente.Finca)) {
                                     const valorCatastral = toNumber($scope.expediente.Finca.Finca.ValorCatastral);
                                     const superficieTotal = toNumber($scope.expediente.Finca.Finca.SuperficieTotal);
                                     const servidumbreSubterranea = toNumber($scope.expediente.ServidumbreSubterranea);
                                     const servidumbreAerea = toNumber($scope.expediente.ServidumbreAerea);
                                
                                     valoracionServidumbreSubterranea = (valorCatastral * servidumbreSubterranea * 0.6) / superficieTotal;
                                     valoracionServidumbreAerea = (valorCatastral * servidumbreAerea * 0.4) / superficieTotal;
                                 }

                                 $scope.valoracionServidumbreSubterranea = valoracionServidumbreSubterranea.toFixed(2).replace(".", ",");
                                 $scope.valoracionServidumbreAerea = valoracionServidumbreAerea.toFixed(2).replace(".", ",");

                                 $scope.DepositoPrevioCalcular();
                                 $scope.datoExpedienteActualizar();
                                 guardarDatosFinca(false);
                             });
                    }
                    else {
                        $scope.DepositoPrevioCalcular();
                        $scope.datoExpedienteActualizar();
                        guardarDatosFinca(false);
                    }
                }

                function esFincaArrendada(finca) {
                    if (finca.FincaArrendada) {
                        return true;
                    }
                    return false;
                }
                //Propietarios
                $scope.DatosPropietariosCargar = function () {
                    $scope.pestanyavisible = 'propietarios';
                    if ($scope.expediente.PropietariosFinca == "") {
                        expedientesService.getPropietariosPorExpediente($scope.ExpedienteId)
                             .success(function (data) {
                                 $scope.expediente.PropietariosFinca = data;
                                 $scope.FechasExpedienteConvertirUTCALocal("propietarios");
                             });
                    }
                }
                //Imágenes						 
                $scope.DatosImagenesCargar = function () {
                    $scope.pestanyavisible = 'imagenes';
                    if ($scope.expediente.Imagenes == "") {
                        expedientesService.getImagenesExpediente($scope.ExpedienteId)
                            .success(function (data) {
                                $scope.expediente.Imagenes = data;
                            });
                    }
                }

                //PlanosClave
                $scope.DatosPlanosClaveCargar = function () {
                    if ($scope.expediente.PlanosClave == "") {
                        expedientesService.getPlanosClave($scope.ExpedienteId)
                            .success(function (data) {
                                $scope.expediente.PlanosClave = data;
                            });
                    }
                }

                //Planos
                $scope.DatosPlanosCargar = function () {
                    if ($scope.expediente.Planos == "") {
                        expedientesService.getPlanos($scope.ExpedienteId)
                            .success(function (data) {
                                $scope.expediente.Planos = data;
                            });
                    }
                }

                $scope.PestanyaPlanosCargar = function () {
                    $scope.pestanyavisible = 'planos';

                    $scope.DatosPlanosClaveCargar();
                    $scope.DatosPlanosCargar();
                }
                //Fin pestanyas cargar

                var $lightbox = $('#lightbox');

                $scope.showModal = function ($event) {
                    debugger
                    var $img = $($event.currentTarget).find('img'),
                        src = $img.attr('src'),
                        alt = $img.attr('alt'),
                        css = {
                            'maxWidth': '100%',
                            'maxHeight': $(window).height() - 100
                        };

                    $lightbox.find('.close').addClass('hidden');
                    $lightbox.find('img').attr('src', src);
                    $lightbox.find('img').attr('alt', alt);
                    $lightbox.find('img').css(css);
                };

                $lightbox.on('shown.bs.modal', function (e) {
                    var $img = $lightbox.find('img');

                    $lightbox.find('.modal-dialog').css({ 'width': $img.width() });
                    $lightbox.find('.close').removeClass('hidden');
                });

                function aniadirFechaAHora(hora)
                {
                    if (hora != null && hora != "") {
                        var horaDate  = new Date("1970-01-01T" + hora + ":00");
                        return horaDate;
                    }
                    return hora;
                }
           
                function getImporteValoracion(expediente) {
                
                    if (hayValoracionTS()) return expediente.ImporteTS;
                    if (hayValoracionTSJA()) return expediente.ImporteTSJA;
                    if (hayValoracionRJP()) return expediente.ImporteRJP;
                    if (hayValoracionHAA()) return calcularValoracionHAA();
                    if (hayValoracionMA()) return expediente.ImporteMA;
                    if (hayValoracionCOT()) return expediente.ImporteCOT;
                
                    return "0";

                
                    function hayValoracionTS() {
                        return (expediente.AsignacionExpedienteTS && expediente.ImporteTS != null && expediente.ImporteTS != '');
                    }
                
                    function hayValoracionTSJA() {
                        return (expediente.AsignacionExpedienteTSJA && expediente.ImporteTSJA != null && expediente.ImporteTSJA != '');
                    }
                    function hayValoracionRJP() {
                        return (expediente.AsignacionExpedienteRJP && expediente.ImporteRJP != null && expediente.ImporteRJP != '');
                    }
                
                    function hayValoracionHAA() {
                        return (expediente.AsignacionExpedienteHAA &&  expediente.ImporteHAA != null && expediente.ImporteHAA != '');
                    }
                
                    function calcularValoracionHAA() {
                        return Utilidades.stringFormat(Utilidades.numberFormat(expediente.ImporteHAA) + (Utilidades.numberFormat(expediente.TotalAplicarAfeccion5) * 0.05));
                    }

                    function hayValoracionMA() {
                        return (expediente.AsignacionExpedienteMA && expediente.ImporteMA != null && expediente.ImporteMA != '');
                    }
                    function hayValoracionCOT() {
                        return (expediente.AsignacionExpedienteCOT && expediente.ImporteCOT != null && expediente.ImporteCOT != '');
                    }
                }
            
                /// Convierte cadena de número en formato de España (1.234,56) a número.
                /// Si la cadena no es un número válido, devuelve 0
                function toNumber(str) {
                    const value = parseFloat(str.replace(/\./g, "").replace(",", "."));
                    return isFinite(value) ? value : 0.0;
                }
            
                $scope.seleccionarTodosOficios = function () {
                    $scope.SeleccionadosTodosOficios = !$scope.SeleccionadosTodosOficios;
                
                    $scope.archivosExpediente.filter($scope.esOficio).forEach(function (archivo) {
                        archivo.selected = $scope.SeleccionadosTodosOficios;
                    });
                }
                $scope.seleccionarTodosImpresos = function () {
                    $scope.SeleccionadosTodosImpresos = !$scope.SeleccionadosTodosImpresos;

                    $scope.archivosExpediente.filter($scope.esImpreso).forEach(function (archivo) {
                        archivo.selected = $scope.SeleccionadosTodosImpresos;
                    });
                }

                $scope.seleccionarTodosOtros = function () {
                    $scope.SeleccionadosTodosOtros = !$scope.SeleccionadosTodosOtros;

                    $scope.archivosExpediente.filter($scope.esOtro).forEach(function (archivo) {
                        archivo.selected = $scope.SeleccionadosTodosOtros;
                    });
                }
            
                $scope.eliminarArchivosSeleccionados = function () {
                    var idArchivos = [];
                    $scope.archivosExpediente.forEach(function (archivo) {
                        if (archivo.selected) {
                            idArchivos.push(archivo.Id);
                        }
                    });
                    if (idArchivos.length == 0) {
                        alert('No hay elementos seleccionados. No se ha eliminado nada');
                        return;
                    }
                    var archivosEliminar = idArchivos.toString();
                
                    expedientesService.eliminarArchivos(archivosEliminar)
                        .then(function (response) {
                            alert(response.data);
                            expedientesService.getListadoDocumentos($scope.ExpedienteId)
                                .then(function (response) {
                                    $scope.archivosExpediente = response.data;
                                })
                        }).catch(function (response) {
                            $scope.error = expedientesService.obtenerPrimerErrorModelState(response.data);
                            alert($scope.error);
                        });
                }

                $scope.actualizarJP = function () {            
                    $scope.expediente.AsignacionExpedienteJP = ($scope.expediente.HayFirmaHAA == 0 ? $scope.expediente.AsignacionExpedienteJP = "JP" : $scope.expediente.AsignacionExpedienteJP = "");
                }

                function hayQueCalcularSumaAfeccion5(codigoEstadoActual) {
                    return codigoEstadoActual == "HAA" || codigoEstadoActual == "RJP" || codigoEstadoActual == "TSJA" || codigoEstadoActual == "TS";
                }
            
                function resetCompareceAP() {
                    $scope.expediente.CompareceCitacionAP = null;
                }
                $scope.resetCompareceAP = resetCompareceAP;
            
                function resetAutorizaAP() {
                    $scope.expediente.AutorizaAP = null;
                }
                $scope.resetAutorizaAP = resetAutorizaAP;

                function resetConRenuncia() {
                    $scope.expediente.SinRenunciaAO = null;
                }
                $scope.resetConRenuncia = resetConRenuncia;

                function resetHayFirmaMA() {
                    $scope.expediente.HayFirmaMA = null;
                }
                $scope.resetHayFirmaMA = resetHayFirmaMA;

                function resetHayValoracionHAP() {
                    $scope.expediente.HayValoracionPropietario = null;
                }
                $scope.resetHayValoracionHAP = resetHayValoracionHAP;

                function resetHayFirmaHAA() {
                    $scope.expediente.HayFirmaHAA = null;
                }
                $scope.resetHayFirmaHAA = resetHayFirmaHAA;

                function resetHayResolucionJP() {
                    $scope.expediente.HayResolucionJP = null;
                }
                $scope.resetHayResolucionJP = resetHayResolucionJP;

                function resetSeAceptaResolucionJP() {
                    $scope.expediente.ResolucionJPAceptada = null;
                }
                $scope.resetSeAceptaResolucionJP = resetSeAceptaResolucionJP;

                function resetSeAceptaResolucionTSJ() {
                    $scope.expediente.ResolucionTSJAAceptada = null;
                }
                $scope.resetSeAceptaResolucionTSJ = resetSeAceptaResolucionTSJ;

                function clickCheckElementoValoracion(indice){
                    getElementosValoracion().forEach(function (value, index) {
                        if (indice != index)
                            value.Seleccionado = false;
                        // Cuando indice == index, no hacer nada. 
                        // Ya se habrá seleccionado o deseleccionado con el click
                    });
                }
                $scope.clickCheckElementoValoracion = clickCheckElementoValoracion;

                function activarBotonSubirElementoValoracion() {
                    var indiceSeleccionado = getIndiceElementoValoracionSeleccionado();
                    return indiceSeleccionado > 0;
                }
                $scope.activarBotonSubirElementoValoracion = activarBotonSubirElementoValoracion;
            
                function getIndiceElementoValoracionSeleccionado() {
                    var elementos = getElementosValoracion();
                    return getIndiceSeleccionado(elementos);
            }
            
                function activarBotonBajarElementoValoracion() {
                    var indiceSeleccionado = getIndiceElementoValoracionSeleccionado();
                    return indiceSeleccionado > -1 && indiceSeleccionado < getElementosValoracion().length - 1;
                }
                $scope.activarBotonBajarElementoValoracion = activarBotonBajarElementoValoracion;

                function getElementosValoracion() {
                    return $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ElementosValoracion;
                }

                var SentidoDesplazamiento = {
                    HACIA_ARRIBA : -1,
                    HACIA_ABAJO : 1
                };
            
                // Deplaza el elemento que esté seleccionado en el array elementos (tienen que 
                // tener un campo "Seleccionado") hacia arriba o hacia abajo(según se mostrarían 
                //en pantalla, es decir, "arriba" es hacia el inicio del array)
                function desplazarElementoSeleccionado(elementos, sentido) {
                    var i = getIndiceSeleccionado(elementos);
                    swap(elementos, i, i + sentido);
                    renumerarElementosValoracion(elementos);
                }
            
                function bajarElementoValoracion() {
                    var elementos = getElementosValoracion();
                    desplazarElementoSeleccionado(elementos, SentidoDesplazamiento.HACIA_ABAJO);
                }
                $scope.bajarElementoValoracion = bajarElementoValoracion;

            
                function subirElementoValoracion() {
                    var elementos = getElementosValoracion();
                    desplazarElementoSeleccionado(elementos, SentidoDesplazamiento.HACIA_ARRIBA);
                }
                $scope.subirElementoValoracion = subirElementoValoracion;

                function renumerarElementosValoracion(array) {
                    var i = 1;
                    array.forEach(function (elem) {
                        elem.Ordinal = i++;
                    })
                }
                function clickCheckElementoValoracionOT(indice){
                    getElementosValoracionOT().forEach(function (value, index) {
                        if (indice != index)
                            value.Seleccionado = false;
                        // Cuando indice == index, no hacer nada. 
                        // Ya se habrá seleccionado o deseleccionado con el click
                    });
                }
                $scope.clickCheckElementoValoracionOT = clickCheckElementoValoracionOT;

                function activarBotonSubirElementoValoracionOT() {
                    var indiceSeleccionado = getIndiceElementoValoracionOTSeleccionado();
                    return indiceSeleccionado > 0;
                }
                $scope.activarBotonSubirElementoValoracionOT = activarBotonSubirElementoValoracionOT;
            
                function getIndiceElementoValoracionOTSeleccionado() {
                    var elementos = getElementosValoracionOT();
                    return getIndiceSeleccionado(elementos);
                }

                function getIndiceSeleccionado(elementos)
                {
                    try {
                        var indiceSeleccionado = elementos.findIndex(function (value) {
                            return value.Seleccionado;
                        });
                        return indiceSeleccionado;
                    }
                    catch (error)
                    {
                        return -1;
                    }
                }

                function activarBotonBajarElementoValoracionOT() {
                    var indiceSeleccionado = getIndiceElementoValoracionOTSeleccionado();
                    return indiceSeleccionado > -1 && indiceSeleccionado < getElementosValoracionOT().length - 1;
                }
                $scope.activarBotonBajarElementoValoracionOT = activarBotonBajarElementoValoracionOT;

                function getElementosValoracionOT() {
                    return $scope.datosValoracionExpediente[$scope.OrdinalValoracionActual].ElementosValoracionOT;
                }

                function bajarElementoValoracionOT() {
                    var elementos = getElementosValoracionOT();
                    desplazarElementoSeleccionado(elementos, SentidoDesplazamiento.HACIA_ABAJO);
                }
                $scope.bajarElementoValoracionOT = bajarElementoValoracionOT;

                function subirElementoValoracionOT() {
                    var elementos = getElementosValoracionOT();
                    desplazarElementoSeleccionado(elementos, SentidoDesplazamiento.HACIA_ARRIBA);
                }
                $scope.subirElementoValoracionOT = subirElementoValoracionOT;

                function swap(array, from, to) {
                    var tmp = array[from];
                    array[from] = array[to];
                    array[to] = tmp;
                }

            }]);
