angular
    .module('terminosMunicipalesAdmin')
    .factory('terminosMunicipalesAdminService', ['$http', '$q', '__env', function terminosMunicipalesAdminService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var terminosMunicipalesUrl = 'terminosmunicipales';

        function getTerminosMunicipales(pageSize, pageNumber, terminomunicipal, provincia) {
            var paginacion = "";
            if (typeof pageSize !== "undefined" && typeof pageNumber !== "undefined") {
                paginacion = '/' + pageSize + '/' + pageNumber;
            }
            var criteriosBusqueda = "";
            criteriosBusqueda += terminomunicipal.replace("/", "__") + ",";
            criteriosBusqueda += provincia.replace("/", "__");

            return $http.get(serviceUrl + terminosMunicipalesUrl + '/admin/' + criteriosBusqueda + paginacion);
        }

        function getProvincias() {
            return $http.get(serviceUrl + terminosMunicipalesUrl + '/provincias');
        }
        function putTerminosMunicipales(terminosMunicipales,pageSize, pageNumber, terminomunicipal, provincia) {
            var paginacion = "";
            if (typeof pageSize !== "undefined" && typeof pageNumber !== "undefined") {
                paginacion = '/' + pageSize + '/' + pageNumber;
            }
            var criteriosBusqueda = "";
            criteriosBusqueda += terminomunicipal.replace("/", "__") + ",";
            criteriosBusqueda += provincia.replace("/", "__");

            return $http.put(serviceUrl + terminosMunicipalesUrl + '/guardar/' + criteriosBusqueda + paginacion, terminosMunicipales);
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    errores.push(response.ModelState[key][i]);
                }
            }
            return errores[0];
        }

        return {
            getTerminosMunicipales: getTerminosMunicipales,
            getProvincias: getProvincias,
            putTerminosMunicipales: putTerminosMunicipales,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState
        }
    }]);
