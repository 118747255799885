angular.module('elementosCuadroPreciosAdmin', [])
       .controller('elementosCuadroPreciosAdminController', ['$scope', '$location', 'elementosCuadroPreciosAdminService', 'Excel', function ($scope, $location, elementosCuadroPreciosAdminService, Excel) {
           elementosCuadroPreciosAdminService.getElementosCuadroPrecios()
                             .success(function (data) {
                                 $scope.elementosCuadroPrecios = data;
                             });

           elementosCuadroPreciosAdminService.getConceptos()
                  .success(function (data) {
                      $scope.conceptos = data;
                  });
           elementosCuadroPreciosAdminService.getClasificaciones()
                  .success(function (data) {
                      $scope.clasificaciones = data;
                  });
           elementosCuadroPreciosAdminService.getUnidadesElementos()
                  .success(function (data) {
                      $scope.unidadesElementos = data;
                  });
           $scope.idNuevo = 0;

           $scope.nuevoElementoCuadroPrecios = function () {
               $scope.idNuevo --;
               var elementoCuadroPrecios = {
                   Id: $scope.idNuevo,
                    Descripcion : "",
                    Concepto: "",
                    Clasificacion : "",
                    UnidadesElemento: "",
                    Precio: null,
                    Afeccion5: true
               };
               $scope.elementosCuadroPrecios.push(elementoCuadroPrecios);
           }
           $scope.eliminarElementoCuadroPrecios = function (elementoCuadroPrecios) {
               var index = $scope.elementosCuadroPrecios.indexOf(elementoCuadroPrecios);
               $scope.elementosCuadroPrecios.splice(index, 1);
           }

           $scope.guardarElementosCuadroPrecios = function () {
               elementosCuadroPreciosAdminService.putElementosCuadroPrecios($scope.elementosCuadroPrecios)
                            .success(function (data) {
                                alert("Datos guardados correctamente");
                            })
                           .error(function (response) {
                               $scope.error = elementosCuadroPreciosAdminService.obtenerPrimerErrorModelState(response);
                               alert($scope.error);
                           });
           }

           $scope.exportarExcel = function () {
               Excel.exportar('dvCuadroPrecios', 'tbCuadroPrecios', 'CuadroPrecios');
           };
       }]);
