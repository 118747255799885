angular
    .module('controlexpedientes')
    .factory('controlexpedientesService', ['$http', '$q', '$filter', '__env', function clavesService($http, $q, $filter, __env) {
        var serviceUrl = __env.apiUrl;

        function getClaves() {
            return $http.get(serviceUrl + "claves/clavestmae");
        }

        function getClasesPagoExpediente() {
            return $http.get(serviceUrl + "clasespagoexpediente");
        }

        function getPagoExpediente(claveId, terminoMunicipalId, asigExpId) {
            return $http.get(serviceUrl + "pagosexpedientes/" + claveId + '/' + terminoMunicipalId + '/' + asigExpId );
        }
        
        function guardar(claveId, terminoMunicipalId, asigExpId, expedientePago) {
            if (expedientePago.Id > 0) {
                return $http.put(serviceUrl + "pagosexpedientes/put/" + claveId + '/' + terminoMunicipalId + '/' + asigExpId, expedientePago);
            } else {
                return $http.post(serviceUrl + "pagosexpedientes/post/" + claveId + '/' + terminoMunicipalId + '/' + asigExpId, expedientePago);
            }
        }
      
        function interesesCalcular(claveId, terminoMunicipalId, asigExpId, expedientePago) {
            if (expedientePago.Id > 0) {
                return $http.put(serviceUrl + "pagosexpedientes/intereses/put/" + claveId + '/' + terminoMunicipalId + '/' + asigExpId, expedientePago);
            } else {
                return $http.post(serviceUrl + "pagosexpedientes/intereses/post/" + claveId + '/' + terminoMunicipalId + '/' + asigExpId, expedientePago);
            }
        }

        function getDocumento(tipoDocumento, idClave, idTerminoMunicipal, asignacionExpediente, importe, numFincas) {
            return $http({
                method: 'get',
                url: serviceUrl + 'informes/documento/controlexpediente/' + tipoDocumento + '/' + idClave + '/' + idTerminoMunicipal + '/' + asignacionExpediente + '/' + importe + '/' + numFincas,
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/msword'
                },
                params: {
                }
            });
        }

        function getCitacionNotificacion(idClave, idMunicipio, asignacion, asunto, fechaPago, horaPago, lugarPago, FirmaId, tipoDocumento) {
            return $http({
                method: 'get',
                url: serviceUrl + 'informes/citacionnotificacion/' + idClave + '/' + idMunicipio + '/' + asignacion,
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/octet-stream'
                },
                params: {
                    asunto: asunto,
                    fechaPago: $filter('date')(fechaPago, "yyyy-MM-dd HH:mm:ss"),
                    horaPago: $filter('date')(horaPago, "yyyy-MM-dd HH:mm:ss"),
                    lugarPago: lugarPago,
                    FirmaId: FirmaId,
                    tipoDocumento: tipoDocumento
                }
            });
        }

        function getFirmas(idClave, idTerminoMunicipal) {
            return $http.get(serviceUrl + "firmas/" + idClave + "/"+ idTerminoMunicipal);
        }

        function getListado(tipoListado) {

        }
        
        function borrar(claveId, terminoMunicipalId, asigExpId) {
            return $http.delete(serviceUrl + "pagosexpedientes/delete/" + claveId + '/' + terminoMunicipalId + '/' + asigExpId);
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    return response.ModelState[key][i];
                }
            }
            return errores[0];
        }

        return {
            getClaves: getClaves,
            getClasesPagoExpediente: getClasesPagoExpediente,
            getPagoExpediente: getPagoExpediente,
            guardar: guardar,
            interesesCalcular: interesesCalcular,
            getDocumento: getDocumento,
            getCitacionNotificacion: getCitacionNotificacion,
            getFirmas: getFirmas,
            getListado: getListado,
            borrar: borrar,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState
        }
    }]);
