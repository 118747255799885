angular.module('usuariosAdmin', [])
       .controller('usuariosAdminController', ['$scope', '$location', 'usuariosAdminService', 'Excel', function ($scope, $location, usuariosAdminService, Excel) {
           usuariosAdminService.getUsuarios()
                             .success(function (data) {
                                 $scope.usuarios = data;
                             });

           $scope.verUsuario = function (idUsuario) {
               $location.path('/admin/usuarios/' + idUsuario);
           };

           $scope.nuevoUsuario = function (idUsuario) {
               $location.path('/admin/usuarios/nuevo/');
           };

           $scope.usuariosSeleccionados = "";
           $scope.eliminarUsuarios = function () {
               angular.forEach($scope.usuarios, function (usuario) {
                   if (usuario.Selected) {
                       if ($scope.usuariosSeleccionados != "") {
                           $scope.usuariosSeleccionados = $scope.usuariosSeleccionados + "," + usuario.Id
                       } else {
                           $scope.usuariosSeleccionados = usuario.Id
                       }

                   };
               });
               usuariosAdminService.eliminarUsuarios($scope.usuariosSeleccionados).success(function (result) {
                   alert(result);
                   $scope.usuariosSeleccionados = [];
                   usuariosAdminService.getUsuarios()
                           .success(function (data) {
                               $scope.usuarios = data;
                           });
               }).error(function (response) {
                   $scope.error = usuariosAdminService.obtenerPrimerErrorModelState(response);
                   alert($scope.error);
               });
           };

           $scope.exportarExcel = function () {
               Excel.exportar('dvUsuarios', 'tbUsuarios', 'Usuarios');
           };

           $scope.seleccionarTodos = function () {
               if (!$scope.SeleccionadosTodos) {
                   $scope.SeleccionadosTodos = true;
               } else {
                   $scope.SeleccionadosTodos = false;
               }
               angular.forEach($scope.usuarios, function (usuario) {
                   usuario.Selected = $scope.SeleccionadosTodos;
               });

           };

       }]);
