angular.module('carreterasAdmin', [])
       .controller('carreterasAdminController', ['$scope', '$location', 'carreterasAdminService', 'Excel', function ($scope, $location, carreterasAdminService, Excel) {
           carreterasAdminService.getCarreteras()
                             .success(function (data) {
                                 $scope.carreteras = data;
                             });

           $scope.idNueva = 0;

           $scope.nuevaCarretera = function () {
               $scope.idNueva--;
               var carretera = {
                   Id: $scope.idNueva,
                   Codigo: ""
               };
               $scope.carreteras.push(carretera);
           }
           $scope.eliminarCarretera = function (carretera) {
               var index = $scope.carreteras.indexOf(carretera);
               $scope.carreteras.splice(index, 1);
           }

           $scope.guardarCarreteras = function () {
               carreterasAdminService.putCarreteras($scope.carreteras)
                            .success(function (data) {
                                alert("Datos guardados correctamente");
                            })
                           .error(function (response) {
                               $scope.error = carreterasAdminService.obtenerPrimerErrorModelState(response);
                               alert($scope.error);
                           });
           }

           $scope.exportarExcel = function () {
               Excel.exportar('dvCarreteras', 'tbCarreteras', 'Carreteras');
           };
       }]);