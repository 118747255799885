angular
    .module('cultivosAprovechamientosAdmin')
    .factory('cultivosAprovechamientosAdminService', ['$http', '$q', '__env', function cultivosAprovechamientosAdminService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var cultivosaprovechamientosUrl = 'cultivosaprovechamientos';

        function getCultivosAprovechamientos() {
            return $http.get(serviceUrl + cultivosaprovechamientosUrl);
        }
        function putCultivosAprovechamientos(cultivosAprovechamientos) {
            return $http.put(serviceUrl + cultivosaprovechamientosUrl + '/guardar', cultivosAprovechamientos);
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    errores.push(response.ModelState[key][i]);
                }
            }
            return errores[0];
        }

        return {
            getCultivosAprovechamientos: getCultivosAprovechamientos,
            putCultivosAprovechamientos: putCultivosAprovechamientos,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState
        }
    }]);
