angular.module('expedientes')
       .controller('expedientesTitularController', ['onlineStatus', 'authenticationInfo', '$rootScope', '$scope', '$location', '$route', '$routeParams', 'expedientesService', 'Excel', function (onlineStatus, authenticationInfo, $rootScope, $scope, $location, $route, $routeParams, expedientesService, Excel) {
           //Comprobar la conexion a internet para mostrarlo
           $scope.onlineStatus = onlineStatus;
           $scope.estado = null;
           $scope.authentication = authenticationInfo;
           $scope.$watch('onlineStatus.isOnline()', function (online) {
               $scope.online_status_string = online ? 'online' : 'offline';
               $scope.estado = $scope.online_status_string;
           });

           expedientesService.getTitular($routeParams.id)
                             .success(function (data) {
                                 $scope.titular = data;
                             });

           expedientesService.getExpedientesTitular($routeParams.id)
                             .success(function (data) {
                                 $scope.expedientestitular = data;
                                 //Si el t�tular tiene s�lo un expediente 
                                 //se le redirecciona para verlo directamente
                                 if ($scope.expedientestitular.length == 1) {
                                     $location.path('/expedientestit/' + $scope.expedientestitular[0].ClaveId + "/" + $scope.expedientestitular[0].Id + "/0");
                                 }
                             });
           
           $scope.volver = function () {
               $location.path('/claves');
           }

           $scope.verExpediente = function (idClave,idExpediente) {
               expedientesService.getClave(idClave)
                           .success(function (data) {
                               $rootScope.clave = data;
                               $location.path('/expedientestit/' + idClave + "/" + idExpediente + "/" + $routeParams.id);
                           });
           }

          $scope.exportarExcel = function () {
              //Excel.exportar('dvExpedientes', 'tbExpedientes', 'Expedientes');
          };

       }]);
