angular
    .module('tiposInteresAdmin')
    .factory('tiposInteresAdminService', ['$http', '$q', '__env', function tiposInteresAdminService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var tiposinteresUrl = 'tiposinteres';

        function getTiposInteres() {
            return $http.get(serviceUrl + tiposinteresUrl);
        }
        function putTiposInteres(tiposinteres) {
            return $http.put(serviceUrl + tiposinteresUrl + '/guardar', tiposinteres);
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    errores.push(response.ModelState[key][i]);
                }
            }
            return errores[0];
        }

        return {
            getTiposInteres: getTiposInteres,
            putTiposInteres: putTiposInteres,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState
        }
    }]);
