angular.module('registrosMunicipalesAdmin', [])
       .controller('registrosMunicipalesAdminController', ['$scope', '$location', 'registrosMunicipalesAdminService', /*'Excel',*/ function ($scope, $location, registrosMunicipalesAdminService/*, Excel*/) {
           registrosMunicipalesAdminService.getRegistrosMunicipales()
                             .success(function (data) {
                                 $scope.registrosMunicipales = data;
                             });

           registrosMunicipalesAdminService.getTerminosMunicipales()
                  .success(function (data) {
                      $scope.terminosMunicipales = data;
                  });
           $scope.idNuevo = 0;

           $scope.nuevoRegistroMunicipal = function () {
               $scope.idNuevo--;
               var registroMunicipal = {
                   Id: $scope.idNuevo,
                   Registro: "",
                   TerminoMunicipalId: null
               };
               //$scope.registrosMunicipales.push(registroMunicipal);
               //Insertar en primera posición
               $scope.registrosMunicipales.unshift(registroMunicipal);
           }
           $scope.eliminarRegistroMunicipal = function (registroMunicipal) {
               var index = $scope.registrosMunicipales.indexOf(registroMunicipal);
               $scope.registrosMunicipales.splice(index, 1);
           }

           $scope.tmSel = null;
           $scope.seleccionarTerminoMunicipal = function (terminoMunicipal) {
               $scope.registrosMunicipales[$scope.tmSel].TerminoMunicipalId = terminoMunicipal.Id;
               $scope.registrosMunicipales[$scope.tmSel].TerminoMunicipal = {
                   Id: terminoMunicipal.Id,
                   Municipio: terminoMunicipal.Municipio
               };
               $('#myModalTerminosMunicipales').modal('hide');
           }

           $scope.guardarRegistrosMunicipales = function () {
               registrosMunicipalesAdminService.putRegistrosMunicipales($scope.registrosMunicipales)
                            .success(function (data) {
                                alert("Datos guardados correctamente");
                            })
                           .error(function (response) {
                               $scope.error = registrosMunicipalesAdminService.obtenerPrimerErrorModelState(response);
                               alert($scope.error);
                           });
           }

           /*$scope.exportarExcel = function () {
               Excel.exportar('dvRegistrosMunicipales', 'tbRegistrosMunicipales', 'RegistrosMunicipales');
           };*/
       }]);