angular.module('claves', [])
       .controller('clavesController', ['$scope', '$rootScope', '$location', '$route', '$routeParams', 'onlineStatus', 'authenticationInfo', 'clavesService', '$filter', '$compile', 'Excel', 'uiGmapGoogleMapApi', function ($scope, $rootScope, $location, $route, $routeParams, onlineStatus, authenticationInfo, clavesService, $filter, $compile, Excel, uiGmapGoogleMapApi) {
           //Comprobar la conexion a internet para mostrarlo
           $scope.onlineStatus = onlineStatus;
           $scope.estado = null;
           $scope.authentication = authenticationInfo;
           $scope.$watch('onlineStatus.isOnline()', function (online) {
               $scope.online_status_string = online ? 'online' : 'offline';
               $scope.estado = $scope.online_status_string;
           });


           //GIS
           $scope.TabIniGIS = ($routeParams.gisC == 1) ? 1 : 0;
           $scope.polygons = [];
           $scope.GISiniciado = 0;

           $scope.verDetalleObra = function (idClave) {
               $rootScope.centerClaves = $scope.map.control.getGMap().getCenter();
               $rootScope.zoomClaves = $scope.map.control.getGMap().getZoom();
               $location.url('/expedientes/' + idClave + "?gisC=1");
           }

           $scope.descargarArchivo = function (idArchivo, nombre) {
                clavesService.getArchivo(idArchivo)
                     .success(function (data, headers) {
                         var filename = nombre;
                         var contentType = 'undefined';

                         var blob = new Blob([data],
                         { type: contentType });
                         if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                             window.navigator.msSaveBlob(blob, filename);
                         else //Resto
                         {
                             var downloadLink = angular.element('<a></a>');
                             downloadLink.attr('href', window.URL.createObjectURL(blob));
                             downloadLink.attr('download', filename);
                             downloadLink[0].click();
                         }
                     })
                     .error(function (response) {
                         $scope.error = clavesService.obtenerPrimerErrorModelState(response);
                         alert('Error al obtener el archivo.');
                     });
            }

           $scope.cargarMapa = function () {
                var bounds = new google.maps.LatLngBounds();
                var latLong;
                $scope.kmlLayerOptions = [];
                $scope.markersClaves = [];

                var i = -1;

               //Cargar chinchetas sólo con zoom lejano
                if ($rootScope.zoomClaves < 14 || $rootScope.zoomClaves == null) {
                    angular.forEach($scope.kmls, function (clave) {
                        if (clave.CoordenadaMarcadorX != null && clave.CoordenadaMarcadorY != null && clave.CoordenadaMarcadorX != '' && clave.CoordenadaMarcadorY != '') {
                            var kmlOptions;
                            var marker = {
                                latitude: clave.CoordenadaMarcadorX,
                                longitude: clave.CoordenadaMarcadorY,
                                title: clave.Clave,
                                archivoId: (clave.Archivos.length > 0) ? clave.Archivos[0].Id : 0,
                                archivoNombre: (clave.Archivos.length > 0) ? clave.Archivos[0].Nombre : '',
                                id: clave.Id,
                                fit: true
                            };

                            $scope.markersClaves.push(marker);

                            latLong = new google.maps.LatLng(clave.CoordenadaMarcadorX, clave.CoordenadaMarcadorY);
                            bounds.extend(latLong);
                        }
                    });
                }

                kmlOptions = {
                    url: $scope.kmls[0].KMLTraza,
                    preserveViewport: true,
                    show: ($rootScope.zoomClaves >= 14) ? true: false
                };

                $scope.kmlLayerOptions.push(kmlOptions);
            };

            $scope.cargarMarcadores = function () {
                $scope.markersClaves = [];
                angular.forEach($scope.kmls, function (clave) {
                    if (clave.CoordenadaMarcadorX != null && clave.CoordenadaMarcadorY != null && clave.CoordenadaMarcadorX != '' && clave.CoordenadaMarcadorY != '') {
                        var marker = {
                            latitude: clave.CoordenadaMarcadorX,
                            longitude: clave.CoordenadaMarcadorY,
                            title: clave.Clave,
                            id: clave.Id,
                            fit: true,
                            archivoId: (clave.Archivos.length > 0) ? clave.Archivos[0].Id : 0,
                            archivoNombre: (clave.Archivos.length > 0) ? clave.Archivos[0].Nombre : '',
                            markerOptions: {
                                visible: true
                            }
                        };
                        $scope.markersClaves.push(marker);
                    }
                });

                $scope.$apply();
            };

            $scope.initGIS = function () {
                try {
                    uiGmapGoogleMapApi.then(function (maps) {
                        $scope.GISiniciado = 1;
                        var zoomInicial = 8;
                        if ($rootScope.zoomClaves != null) {
                            zoomInicial = $rootScope.zoomClaves;
                        }
                        $scope.map = {
                            center: {        // set on Demarcación de Carreteras de Aragón as initial default
                                latitude: ($rootScope.centerClaves == null) ? 41.654373 : $rootScope.centerClaves.lat(),
                                longitude: ($rootScope.centerClaves == null) ? -0.878851 : $rootScope.centerClaves.lng()
                            },
                            zoom: zoomInicial,
                            show: true,
                            options: {
                                mapTypeId: google.maps.MapTypeId.SATELLITE
                            },
                            events:
                            {
                                zoom_changed: function (map) {
                                    if (map.getZoom() >= 14) {
                                        //Ocultar markers
                                        $scope.markersClaves=[];
                                        //Mostrar kmls
                                        angular.forEach($scope.kmlLayerOptions, function (kml) {
                                            kml.show = true;
                                        });
                                        $scope.$apply();
                                    }
                                    else {
                                        ////Mostrar markers
                                        $scope.cargarMarcadores();
                                        //Ocultar Kmls
                                        angular.forEach($scope.kmlLayerOptions, function (kml) {
                                            kml.show = false;
                                        });
                                        $scope.$apply();
                                    }
                                },
                            },
                            control: {},
                            KmlLayerCallback: function (layer) {
                                google.maps.event.addListener(layer, 'click', function (event) {
                                    var datosPlano = "<div>No Existe Documentación Gráfica</div>";
                                    if (event.featureData != null) {
                                        if (event.featureData.name != null) {
                                            if (!(isNaN(event.featureData.name))) {
                                                var innerHtmlInfoWindow = "";
                                                angular.forEach($scope.kmls, function (clave) {
                                                    if (clave.Id == event.featureData.name) {
                                                        if (clave.Archivos.length > 0) {
                                                            //datosPlano = "<div><button class=\"btn\" ng-click=\"descargarArchivo(" + clave.Archivos[0].Id + ", '" + clave.Archivos[0].Nombre + "')\" >Documentación Gráfica del Proyecto</button><br></div>";
                                                            datosPlano = "<div><a href=\"\" ng-click=\"descargarArchivo(" + clave.Archivos[0].Id + ", '" + clave.Archivos[0].Nombre + "');\">Documentación Gráfica del Proyecto</a><br></div>";
                                                        }
                                                        innerHtmlInfoWindow = "<div style=\"font-family: Roboto,Arial,sans-serif; font-size: small;\"><div style=\"font-size: medium; font-weight: 500; margin-bottom: 0em;\">" + clave.Clave + "</div><div><a ng-click=\"verDetalleObra(" + event.featureData.name + ");\" style=\"cursor: pointer;\">Ver Obra en Detalle</a><br></div>" + datosPlano + "</div>";
                                                    }
                                                });
                                                //Cambiar opacidad feature capa clickada
                                                /*angular.forEach($scope.kmlLayerOptions, function (kml) {
                                                    if (kml.id == event.featureData.id) {
                                                        //kml
                                                        var i = 0;
                                                    }
                                                });*/
                                                var htmCompilado = $compile(angular.element(innerHtmlInfoWindow))($scope);
                                                event.featureData.infoWindowHtml = htmCompilado[0];
                                            }
                                        }
                                    }
                                });
                            },
                        }
                    })

                    $scope.cargarMapa();
                } catch (e) {
                    alert(e);
                }
            }

            $("a[data-target='#gis']").on('shown.bs.tab', function () {
                if ($scope.GISiniciado == 0) {
                    clavesService.getKmls()
                            .success(function (data) {
                                $scope.kmls = data;
                                $scope.initGIS();
                            });
                }
            });
           //Fin GIS

           $scope.searchCodigoClave = '';
           $scope.searchAlias = '';
           $scope.searchProyecto = '';
           $scope.searchFechaInicioExpediente = '';
           clavesService.getClaves(15, 1, $scope.searchCodigoClave, $scope.searchAlias, $scope.searchProyecto, $scope.searchFechaInicioExpediente)
                   .success(function (data) {
                       $scope.claves = data.claves;
                       $scope.pageSizeC = data.pageSize;
                       $scope.recordsNumberC = data.recordsNumber;
                       $scope.pageNumberC = data.pageNumber;
                   });

           $scope.searchNombre = '';
           $scope.searchApellido1 = '';
           $scope.searchApellido2 = '';
           $scope.searchCodigoClave = '';
           $scope.searchNIF = '';           
           clavesService.getTitulares(15, 1, $scope.searchNombre, $scope.searchApellido1, $scope.searchApellido2, $scope.searchCodigoClave, $scope.searchNIF)
                    .success(function (data) {
                        $scope.titulares = data.titulares;
                        $scope.pageSizeP = data.pageSize;
                        $scope.recordsNumberP = data.recordsNumber;
                        $scope.pageNumberP = data.pageNumber;
                  });

            $scope.pageClavesChanged = function () {
                var size = 15;
                var number = 1;
                if (typeof $scope.pageSizeC !== "undefined" && typeof $scope.pageNumberC !== "undefined") {
                    size = $scope.pageSizeC;
                    number = $scope.pageNumberC;
                }

                clavesService.getClaves(size, number, $scope.searchCodigoClave, $scope.searchAlias, $scope.searchProyecto, $scope.searchFechaInicioExpediente)
                   .success(function (data) {
                       $scope.claves = data.claves;
                       $scope.pageSizeC = data.pageSize;
                       $scope.recordsNumberC = data.recordsNumber;
                       $scope.pageNumberC = data.pageNumber;
                   });
            };
            $scope.keyPressedClaves = function (keyEvent) {
                if (keyEvent.which === 13)
                {
                    $scope.pageSizeC = 15;
                    $scope.pageNumberC = 1;
                    $scope.pageClavesChanged();
                }
            };

            $scope.keyPressedTitulares = function (keyEvent) {
                if (keyEvent.which === 13) {
                    $scope.pageSizeP = 15;
                    $scope.pageNumberP = 1;
                    $scope.pagePropietariosChanged();
                }
            };

            $scope.pagePropietariosChanged = function () {
                var size = 15;
                var number = 1;
                if (typeof $scope.pageSizeP !== "undefined" && typeof $scope.pageNumberP !== "undefined") {
                    size = $scope.pageSizeP;
                    number = ($scope.pageNumberP);
                }

                clavesService.getTitulares(size, number, $scope.searchNombre, $scope.searchApellido1, $scope.searchApellido2, $scope.searchNIF, $scope.searchCodigoClave)
                    .success(function (data) {
                        $scope.titulares = data.titulares;
                        $scope.pageSizeP = data.pageSize;
                        $scope.recordsNumberP = data.recordsNumber;
                        $scope.pageNumberP = data.pageNumber;
                    });
            };

           $scope.editarPropiedadesClave = function (idClave) {
               $location.path('/claves/' + idClave);
           };
           $scope.nuevaClave = function () {
               $location.path('/claves/0');
           }
           $scope.verClave = function (idClave) {
               $location.url('/expedientes/' + idClave + '?gisC=0');
           };

           $scope.verTitular = function (idTitular) {
               $location.path('/expedientestitular/' + idTitular);
           };

           $scope.exportarExcel = function () {
                    //Excel.exportar('dvClaves', 'datos', 'Claves');
           };
           $scope.controlExpedientes = function () {
               $location.path('/controlexpedientes/0/0');
           }

           if ($scope.TabIniGIS == 1) {
                clavesService.getKmls()
                        .success(function (data) {
                            $scope.kmls = data;
                            $scope.initGIS();
                        });
           }

       }])
        .controller('infoWindowCtrl', ['$scope', '$rootScope', '$location', 'clavesService', function ($scope, $rootScope, $location, clavesService) {
            if (document.getElementById('archivoIdMarker').value=='0') {
                document.getElementById('divConArchivo').style.display = 'none';
                document.getElementById('divSinArchivo').style.display = 'block';
            } else {
                document.getElementById('divConArchivo').style.display = 'block';
                document.getElementById('divSinArchivo').style.display = 'none';
            }

            $scope.verDetalleObra = function () {
                idClave = document.getElementById('idMarker').value;
                $rootScope.centerClaves = $scope.$parent.$parent.$parent.$parent.$parent.$parent.control.getGMap().getCenter();
                $rootScope.zoomClaves = $scope.$parent.$parent.$parent.$parent.$parent.$parent.control.getGMap().getZoom();
                $location.url('/expedientes/' + idClave + "?gisC=1");
            }

            $scope.descargarArchivo = function () {
                idArchivo = document.getElementById('archivoIdMarker').value;
                nombre = document.getElementById('archivoNombreMarker').value;
                clavesService.getArchivo(idArchivo)
                     .success(function (data, headers) {
                         var filename = nombre;
                         var contentType = 'undefined';

                         var blob = new Blob([data],
                         { type: contentType });
                         if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                             window.navigator.msSaveBlob(blob, filename);
                         else //Resto
                         {
                             var downloadLink = angular.element('<a></a>');
                             downloadLink.attr('href', window.URL.createObjectURL(blob));
                             downloadLink.attr('download', filename);
                             downloadLink[0].click();
                         }
                     })
                     .error(function (response) {
                         $scope.error = clavesService.obtenerPrimerErrorModelState(response);
                         alert('Error al obtener el archivo.');
                     });
            }
        }]);

/*function descargarDocumento(id, nombre) {
    angular.element(document.getElementById('divClaves')).scope().descargarArchivo(id, nombre);
}*/