angular.module('plantillasWordAdmin', [])
       .controller('plantillasWordAdminController', ['$scope', '$location', 'plantillasWordAdminService', function ($scope, $location, plantillasWordAdminService) {
           plantillasWordAdminService.getPlantillasWord()
                             .success(function (data) {
                                 $scope.plantillasword = data;
                             });

           $scope.descargarPlantilla = function (nombre) {
               plantillasWordAdminService.getPlantillaWord(nombre)
                   .success(function (data, headers) {
                       var filename = nombre;
                       var contentType = 'application/msword';

                       var blob = new Blob([data],
                       { type: contentType });

                       if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                           window.navigator.msSaveBlob(blob, filename);
                       else //Resto
                       {
                           var downloadLink = document.createElement("a");

                           var url = window.URL.createObjectURL(blob);
                           downloadLink.setAttribute("target", "_blank");
                           downloadLink.setAttribute("href", url);
                           downloadLink.setAttribute("download", filename);
                           document.body.appendChild(downloadLink);
                           downloadLink.click();
                           document.body.removeChild(downloadLink);
                       }
                   })
                    .error(function (response) {
                        $scope.error = expedientesService.obtenerPrimerErrorModelState(response);
                        alert('Error al descargar plantilla.');
                    });
           }

           $scope.dotName;
           $scope.uploadFile = function (input) {
               if (input.files && input.files[0]) {
                   var reader = new FileReader();
                   reader.onload = $scope.dotIsLoaded;
                   $scope.dotName = input.files[0].name;
                   reader.readAsDataURL(input.files[0]);
               }
           };
           $scope.dotIsLoaded = function (e) {
               $scope.$apply(function () {
                   var plantilla = {
                       Nombre: $scope.dotName,
                       PlantillaWord: e.target.result.replace(/data:application\/vnd.openxmlformats-officedocument.wordprocessingml.template;base64,/g, '')
                   }
                   $scope.dotName = null;

                   plantillasWordAdminService.postPlantillaWord(plantilla)
                       .success(function (data) {
                            alert('Plantilla Word actualizada correctamente');
                       })
                        .error(function (response) {
                            $scope.error = plantillasWordAdminService.obtenerPrimerErrorModelState(response);
                            alert($scope.error);
                        });
               });
           }

       }]);
