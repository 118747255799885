angular.module('terminosMunicipalesAdmin', [])
       .controller('terminosMunicipalesAdminController', ['$scope', '$location', 'terminosMunicipalesAdminService', /*'Excel',*/ function ($scope, $location, terminosMunicipalesAdminService/*, Excel*/) {

           $scope.searchTerminoMunicipal = '';
           $scope.searchProvincia = '';
           terminosMunicipalesAdminService.getTerminosMunicipales(15, 1, $scope.searchTerminoMunicipal, $scope.searchProvincia)
                   .success(function (data) {
                       $scope.terminosMunicipales = data.terminosMunicipales;
                       $scope.pageSizeTM = data.pageSize;
                       $scope.recordsNumberTM = data.recordsNumber;
                       $scope.pageNumberTM = data.pageNumber;
                   });

           terminosMunicipalesAdminService.getProvincias()
                  .success(function (data) {
                      $scope.provincias = data;
                  });
           $scope.idNuevo = 0;

           $scope.pageTerminosMunicipalesChanged = function () {
               var size = 15;
               var number = 1;
               if (typeof $scope.pageSizeTM !== "undefined" && typeof $scope.pageNumberTM !== "undefined") {
                   size = $scope.pageSizeTM;
                   number = $scope.pageNumberTM;
               }

               terminosMunicipalesAdminService.getTerminosMunicipales(size, number, $scope.searchTerminoMunicipal, $scope.searchProvincia)
                  .success(function (data) {
                      $scope.terminosMunicipales = data.terminosMunicipales;
                      $scope.pageSizeTM = data.pageSize;
                      $scope.recordsNumberTM = data.recordsNumber;
                      $scope.pageNumberTM = data.pageNumber;
                  });
           };

            $scope.keyPressedSearchTerminosMunicipales = function (keyEvent) {
                if (keyEvent.which === 13) {
                    $scope.pageSizeTM = 15;
                    $scope.pageNumberTM = 1;
                    $scope.pageTerminosMunicipalesChanged();
                }
            };

           $scope.nuevoTerminoMunicipal = function () {
               $scope.idNuevo--;
               var terminoMunicipal = {
                   Id: $scope.idNuevo,
                   Municipio: "",
                   Provincia: ""
               };
               //$scope.terminosMunicipales.push(terminoMunicipal);
               //Insertar en primera posición
               $scope.terminosMunicipales.unshift(terminoMunicipal);
           }
           $scope.eliminarTerminoMunicipal = function (terminoMunicipal) {
               var index = $scope.terminosMunicipales.indexOf(terminoMunicipal);
               $scope.terminosMunicipales.splice(index, 1);
           }

           $scope.guardarTerminosMunicipales = function () {
               var size = 15;
               var number = 1;
               if (typeof $scope.pageSizeTM !== "undefined" && typeof $scope.pageNumberTM !== "undefined") {
                   size = $scope.pageSizeTM;
                   number = $scope.pageNumberTM;
               }

               terminosMunicipalesAdminService.putTerminosMunicipales($scope.terminosMunicipales, size, number, $scope.searchTerminoMunicipal, $scope.searchProvincia)
                            .success(function (data) {
                                alert("Datos guardados correctamente");
                            })
                           .error(function (response) {
                               $scope.error = terminosMunicipalesAdminService.obtenerPrimerErrorModelState(response);
                               alert($scope.error);
                           });
           }

           /*$scope.exportarExcel = function () {
               Excel.exportar('dvTerminosMunicipales', 'tbTerminosMunicipales', 'TerminosMunicipales');
           };*/
       }]);