angular.module('home', [])
       .controller('homeController', ['$scope', '$location', 'authenticationInfo', 'onlineStatus', function ($scope, $location, authenticationInfo, onlineStatus) {
           $scope.authentication = authenticationInfo;
           $scope.IsOnLine = onlineStatus.isOnline();

           //Para cuando se accede a la aplicación y se mantiene la autenticación de una sesión anterior
           if (($scope.authentication.isAuthenticated && $scope.IsOnLine) || !($scope.IsOnLine)) {
               $location.path('/claves');
           }

       }]);